import React from "react";
import { Table } from "antd";
import moment from "moment";

function DataTable(props) {
  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => (
        <div className={"boxes-datatable-column"}>
          <div>{index + 1}</div>
        </div>
      ),
    },
    {
      title: "DATE",
      dataIndex: "createdAt",
      render: (text, record, index) => (
        <div className={"boxes-datatable-column"}>
          <div> {moment(text).format("l")}</div>
        </div>
      ),
    },
    {
      title: "Box Number",
      dataIndex: "box_number",
      render: (text, record, index) => (
        <div className={"boxes-datatable-column"}>
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: "User",
      dataIndex: "box_number",
      render: (text, record, index) => (
        <div className={"boxes-datatable-column"}>
          <div>{record?.created_user?.name}</div>
        </div>
      ),
    },
    {
      title: "Service",
      dataIndex: "box_number",
      render: (text, record, index) => (
        <div className={"boxes-datatable-column"}>
          <div>{record?.service?.name}</div>
        </div>
      ),
    },
    {
      title: "Weight",
      dataIndex: "chargable_weight",
      render: (text, record, index) => (
        <div className={"boxes-datatable-column"}>
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "total_charge",
      render: (text, record, index) => (
        <div className={"boxes-datatable-column"}>
          <div>{text}</div>
        </div>
      ),
    },
  ];
  return (
    <div style={{ minHeight: "60vh" }}>
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        size="small"
        rowKey="box_number"
      />
      <br />
    </div>
  );
}

export default DataTable;
