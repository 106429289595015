import { Container } from "react-bootstrap";
function Footer() {
  return (
    <footer className="footer">
      <Container>
        <div>Privacy</div>
      </Container>
    </footer>
  );
}
export default Footer;
