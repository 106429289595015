import React, { useState } from "react";
import { Drawer, Form, Button, Input, Select, Switch, message } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { API, Headers } from "../../Config";
import axios from "axios";
import { SketchPicker } from "react-color";
const { Option } = Select;
function FormModal(props) {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState(
    props.item.status_color ? props.item.status_color : "#000"
  );

  const onFinish = (values) => {
    setLoading(true);
    let obj = {
      status_head: values.status_head,
      status_label: values.status_label,
      status_order: values.status_order,
      status_color: color,
      active: values.active,
      is_visible_to_user: true,
    };
    let URL =
      props.mode === "post"
        ? API.STATUS_ACTION
        : API.STATUS_ACTION + props.item.id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  return (
    <Drawer
      placement="right"
      title={
        <span className="txt4">
          {props.mode === "post" ? "New" : "Update"} Status
        </span>
      }
      width={720}
      onClose={() => props.onClose()}
      visible={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          status_head: props.item && props.item.status_head,
          status_label: props.item && props.item.status_label,
          status_order: props.item && props.item.status_order,
          status_color: props.item && props.item.status_color,
          active: props.item && props.item.active ? props.item.active : false,
        }}
      >
        <Container>
          <Row>
            <Col sm="6" xs="12">
              <Form.Item
                name="status_head"
                label="Head Name"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
              <Form.Item
                name="status_label"
                label="Status Text"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter Text" />
              </Form.Item>
            </Col>
            <Col sm="6" xs="12">
              <Form.Item
                name="status_order"
                label="Priority"
                rules={[{ required: true, message: "required" }]}
              >
                <Input placeholder="Enter" />
              </Form.Item>
              <Form.Item
                label="Active"
                name="active"
                rules={[{ required: true, message: "required" }]}
                valuePropName={props.item.active ? "checked" : "uncheked"}
              >
                <Switch />
              </Form.Item>
            </Col>

            <Form.Item name="status_color" label="Select Color">
              <SketchPicker
                color={color}
                onChangeComplete={(value) => setColor(value.hex)}
              />
            </Form.Item>
          </Row>
          <br />
          <Row>
            <Col sm="6" xs="12">
              <Row>
                <Col sm="6" xs="12">
                  <Button onClick={() => props.onClose()} block>
                    Cancel
                  </Button>
                </Col>
                <Col sm="6" xs="12">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Form>
    </Drawer>
  );
}
export default FormModal;
