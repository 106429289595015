import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { API, Headers } from "../../Config";
import PageHeader from "../../Routes/PageHeader";
import DataTable from "./dataTable";

const { Search } = Input;

function DeliveryStatusUpdate() {
  const [boxnum, SetBoxnum] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSearch = (txt) => {
    if (txt) {
      loadData(txt);
      setLoading(true);
      SetBoxnum(txt);
    } else {
      setData([]);
    }
  };

  const loadData = (id) => {
    try {
      axios
        .get(API.BASR_URL + API.BOXES_LIST + `/box_status/` + id, {
          headers: Headers(),
        })
        .then(function (response) {
          if (response?.status === 200) {
            let data = response?.data;
            setData(data);
            setLoading(false);
          }
        })
        .catch(function (error) {
          setData([]);
          setLoading(false);
          console.log(error);
        });
    } catch (err) {
      setData([]);
      setLoading(false);
      console.log(err);
    }
  };

  const updateStatus = () => {
    console.log("updated");
    try {
      axios({
        method: "post",
        url: API.BASR_URL + API.DELIVERY_STATUS_UPDATE_ONE + boxnum,
        headers: Headers(),
      })
        .then(function (response) {
          setLoading(false);
          if (response?.data?.status) {
            message.success(response?.data?.message);
          } else {
            message.error(response?.data?.message);
          }
        })
        .catch(function (error) {
          setLoading(false);
          message.error("Oops. Something went wrong");
          console.log(error);
        })
        .finally(function () {
          setTimeout(() => {
            setData([]);
            SetBoxnum("");
          }, 1500);
        });
    } catch (err) {
      console.log(err);
      message.error("Oops. Something went wrong");
      setLoading(false);
    }
  };

  return (
    <div>
      <PageHeader
        PageName={"Delivery Status"}
        breadScrum={"Admin / Delivery Status"}
      />
      <div
        className="detailed-tracking-saerch-box"
        style={{
          marginTop: data?.length ? 0 : 200,
        }}
      >
        <Search
          autoFocus={true}
          style={{ width: "500px" }}
          placeholder="Search Box number"
          allowClear
          enterButton="Search"
          size="large"
          onSearch={(txt) => onSearch(txt)}
          value={boxnum}
          onChange={(e) => SetBoxnum(e.target.value)}
        />
      </div>
      {data?.length ? (
        <div>
          <div
            style={{ display: "flex", justifyContent: "end", marginBottom: 10 }}
          >
            <Button onClick={() => updateStatus()} type="primary">
              Update Status
            </Button>
          </div>
          <DataTable data={data} />
        </div>
      ) : null}
    </div>
  );
}

export default DeliveryStatusUpdate;
