import { Collapse } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  BsGrid1X2Fill,
  BsPersonBadgeFill,
  BsPeopleFill,
  BsPersonCheckFill,
} from "react-icons/bs";
import { BiTrip } from "react-icons/bi";
import { BsBoxSeam } from "react-icons/bs";
import {
  IoBusinessSharp,
  IoTicketSharp,
  IoAirplaneSharp,
  IoDocumentTextSharp,
  IoBoatSharp,
  IoGlobeSharp,
  IoListSharp,
  IoPersonAdd,
  IoPerson,
  IoBagHandle,
  IoAccessibility,
  IoBaseball,
  IoRoseOutline,
  IoShuffleSharp,
  IoGitCompareSharp,
  IoReceiptOutline,
  IoRocketOutline,
  IoReaderOutline,
  IoRepeatSharp,
  IoShieldCheckmark,
} from "react-icons/io5";
import { BsCurrencyExchange } from "react-icons/bs";
import { AiOutlineSetting } from "react-icons/ai";
import { ImAirplane } from "react-icons/im";
import { HiCollection } from "react-icons/hi";
import { GrDeliver } from "react-icons/gr";
import { GiCargoCrate, GiControlTower } from "react-icons/gi";
import { SiMusicbrainz } from "react-icons/si";
import { IoMdArchive, IoMdWalk } from "react-icons/io";
import { TbReportMoney, TbReportMedical, TbReport } from "react-icons/tb";
import { GrMapLocation } from "react-icons/gr";
import { BsGraphUp } from "react-icons/bs";
import { MdShoppingCart } from "react-icons/md";
import { FaDollarSign } from "react-icons/fa";
import { TfiDropbox } from "react-icons/tfi";
import { RiBillLine } from "react-icons/ri";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { withRouter } from "react-router-dom";
import DrawerItem from "./DrawerItem";
import { BiBookmarkHeart } from "react-icons/bi";
import { MdOutlineAssignmentReturn } from "react-icons/md";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";
import { MdUpdate } from "react-icons/md";

const { Panel } = Collapse;

function SideBar(props) {
  const [access, setAccess] = useState([]);

  useEffect(() => {
    let accessList = Auth?.user_access.split("|");
    setAccess(accessList);
  }, []);

  const Auth = useSelector((state) => state.Auth.user);
  return (
    <div className="route-side-bar" onMouseEnter={props.open}>
      <DrawerItem
        show={access.includes("1")}
        name={props.status ? "DashBoard" : ""}
        Route={"/auth/home"}
        Icon={<BsGrid1X2Fill />}
        visible={[1, 2, 4, 5]}
      />

      <Collapse
        accordion
        defaultActiveKey={["2"]}
        bordered={false}
        className="collapseSidebar"
        expandIconPosition="end"
        RiArrowDownSFill
        expandIcon={({ isActive }) =>
          isActive ? (
            <IoIosArrowDown size={15} />
          ) : (
            <IoIosArrowForward size={15} />
          )
        }
      >
        <Panel
          header={props.status ? "Master" : <SiMusicbrainz size={20} />}
          key="1"
        >
          <DrawerItem
            show={access.includes("2")}
            name={"Boxe Types"}
            Route={"/auth/boxtype"}
            Icon={<IoBaseball />}
            visible={[1, 2, 4, 5]}
          />

          <DrawerItem
            show={access.includes("3")}
            name={"Branches"}
            Route={"/auth/Branches"}
            Icon={<IoBusinessSharp />}
            visible={[1, 2, 4, 5]}
          />

          <DrawerItem
            show={access.includes("4")}
            name={"Airlines"}
            Route={"/auth/Airlines"}
            Icon={<IoAirplaneSharp />}
            visible={[1, 2, 4, 5]}
          />

          <DrawerItem
            show={access.includes("5")}
            name={"Ports"}
            Route={"/auth/Ports"}
            Icon={<IoBoatSharp />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("6")}
            name={"Drivers"}
            Route={"/auth/Drivers"}
            Icon={<IoMdArchive />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("7")}
            name={"Users"}
            Route={"/auth/Users"}
            Icon={<BsPersonBadgeFill />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("8")}
            name={"Service Type"}
            Route={"/auth/ServiceType"}
            Icon={<IoAccessibility />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("9")}
            name={"Groups"}
            Route={"/auth/Groups"}
            Icon={<IoGlobeSharp />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("10")}
            name={"Customers"}
            Route={"/auth/Customers"}
            Icon={<BsPeopleFill />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("11")}
            name={"Export Broker"}
            Route={"/auth/exportBroker"}
            Icon={<BiBookmarkHeart />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("12")}
            name={"Agents"}
            Route={"/auth/Agents"}
            Icon={<BsPersonCheckFill />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("13")}
            name={"Delivery Agents"}
            Route={"/auth/DeliveryAgent"}
            Icon={<BsPersonCheckFill />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("14")}
            name={"Clearing Agent"}
            Route={"/auth/ClearingAgent"}
            Icon={<IoBagHandle />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("15")}
            name={"Awb Xray Agent"}
            Route={"/auth/AwbXrayAgent"}
            Icon={<IoPerson />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("16")}
            name={"Awb Supplier"}
            Route={"/auth/AwbSupplier"}
            Icon={<IoPersonAdd />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("17")}
            name={"Senders"}
            Route={"/auth/Senders"}
            Icon={<IoMdWalk />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("18")}
            name={"Recievers"}
            Route={"/auth/Recievers"}
            Icon={<IoListSharp />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("19")}
            name={"Thirt Party Senders"}
            Route={"/auth/ThirtPartySenders"}
            Icon={<IoListSharp />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("20")}
            name={"Pincode"}
            Route={"/auth/Pincode"}
            Icon={<IoListSharp />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("21")}
            name={"Countries"}
            Route={"/auth/Countries"}
            Icon={<IoGlobeSharp />}
            visible={[1, 2, 4, 5]}
          />
          {/* <DrawerItem
          show={access.includes("1")}
            name={"Items"}
            Route={"/auth/Items"}
            Icon={<IoListSharp />}
            visible={[1, 2, 4, 5]}
          /> */}
          <DrawerItem
            show={access.includes("22")}
            name={"BoxItem"}
            Route={"/auth/BoxItem"}
            Icon={<IoListSharp />}
            visible={[1, 2, 4, 5]}
          />

          <DrawerItem
            show={access.includes("23")}
            name={"Statuses"}
            Route={"/auth/Statuses"}
            Icon={<IoListSharp />}
            visible={[1, 2, 4, 5]}
          />

          <DrawerItem
            show={access.includes("24")}
            name={"Role"}
            Route={"/auth/Role"}
            Icon={<IoRoseOutline />}
            visible={[1, 2, 4, 5]}
          />

          <DrawerItem
            show={access.includes("25")}
            name={"Exchange Rate"}
            Route={"/auth/ExchangeRates"}
            Icon={<BsCurrencyExchange />}
            visible={[1, 2, 4, 5]}
          />

          <DrawerItem
            show={access.includes("26")}
            name={"User Access"}
            Route={"/auth/UserAccess"}
            Icon={<IoShieldCheckmark />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("56")}
            name={"Reset password"}
            Route={"/auth/restpassword"}
            Icon={<IoRepeatSharp />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("54")}
            name={"Phone Numbers"}
            Route={"/auth/PhoneNumbers"}
            Icon={<IoShieldCheckmark />}
            visible={[1, 2, 4, 5]}
          />
        </Panel>

        <Panel
          header={props.status ? "Operations" : <AiOutlineSetting size={20} />}
          key="2"
        >
          <DrawerItem
            show={access.includes("27")}
            name={"Pickups"}
            Route={"/auth/pickups"}
            Icon={<IoTicketSharp />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("28")}
            name={"Collections"}
            Route={"/auth/Collections"}
            Icon={<HiCollection />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("29")}
            name={"Boxes"}
            Route={"/auth/Boxes"}
            Icon={<BsBoxSeam />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("30")}
            name={"Airway Bills"}
            Route={"/auth/airwaybillpro"}
            Icon={<ImAirplane />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("31")}
            name={"Airway Bills Series"}
            Route={"/auth/AirwayBills"}
            Icon={<IoDocumentTextSharp />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("32")}
            name={"Trip"}
            Route={"/auth/Trip"}
            Icon={<BiTrip />}
            visible={[1, 2, 4, 5]}
          />

          <DrawerItem
            show={access.includes("33")}
            name={"Assign Boxes To AWB"}
            Route={"/auth/assignboxawb"}
            Icon={<GiCargoCrate />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("34")}
            name={"Assign Boxes To Trip"}
            Route={"/auth/assignboxtrip"}
            Icon={<GrDeliver />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("35")}
            name={"Tracking"}
            Route={"/auth/detailedtracking"}
            Icon={<GrMapLocation />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("36")}
            name={"Ref Number"}
            Route={"/auth/reference-number"}
            Icon={<RiBillLine />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("37")}
            name={"Awb To Xray "}
            Route={"/auth/AwbtoXrayTransactions"}
            Icon={<MdOutlineAssignmentReturn />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("38")}
            name={"Third Party Transactions"}
            Route={"/auth/ThirdPartyTransactions"}
            Icon={<TfiDropbox />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("39")}
            name={"Stock"}
            Route={"/auth/Stock"}
            Icon={<MdShoppingCart />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("40")}
            name={"Expense"}
            Route={"/auth/Expense"}
            Icon={<FaDollarSign />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("55")}
            name={"Delivery"}
            Route={"/auth/Delivery"}
            Icon={<TbTruckDelivery />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("57")}
            name={"Delivery Status"}
            Route={"/auth/DeliveryStatusUpdate"}
            Icon={<MdUpdate />}
            visible={[1, 2, 4, 5]}
          />
          {/* <DrawerItem
          show={access.includes("1")}
        name={"Expenses"}
        Route={"/auth/Expenses"}
        Icon={<IoCalculator />}
      />
      <DrawerItem
      show={access.includes("1")}
        name={"Transactions"}
        Route={"/auth/Transactions"}
        Icon={<IoCalculator />}
      /> */}
        </Panel>
        <Panel header={props.status ? "Reports" : <BsGraphUp />} key={3}>
          <DrawerItem
            show={access.includes("41")}
            name={"Reports"}
            Route={"/auth/reports"}
            Icon={<TbReportMoney />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("42")}
            name={"Awb Reports"}
            Route={"/auth/AwbReport"}
            Icon={<TbReportMedical />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("43")}
            name={"Trip Reports"}
            Route={"/auth/TripReport"}
            Icon={<TbReport />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("44")}
            name={"Expence Reports"}
            Route={"/auth/ExpenceReport"}
            Icon={<FaFileInvoiceDollar />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("45")}
            name={"Packing Report"}
            Route={"/auth/PackingReport"}
            Icon={<FaFileInvoiceDollar />}
            visible={[1, 2, 4, 5]}
          />
        </Panel>
        <Panel header={props.status ? "Accounts" : <BsGraphUp />} key={4}>
          <DrawerItem
            show={access.includes("46")}
            name={"Chart Of Accounts"}
            Route={"/auth/ChartOfAccounts"}
            Icon={<IoShuffleSharp />}
            visible={[1, 2, 4, 5]}
          />

          <DrawerItem
            show={access.includes("47")}
            name={"Payment Voucher"}
            Route={`/auth/VoucherPv/PV`}
            Icon={<FaFileInvoiceDollar />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("48")}
            name={"Reciept Voucher"}
            Route={`/auth/VoucherRv/RV`}
            Icon={<IoReceiptOutline />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("49")}
            name={"Journal Voucher"}
            Route={`/auth/VoucherJv/JV`}
            Icon={<IoGitCompareSharp />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("50")}
            name={"Awb Payment Register"}
            Route={"/auth/AirwayBillPayment"}
            Icon={<IoRocketOutline />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("51")}
            name={"Statement "}
            Route={"/auth/StatementPage"}
            Icon={<IoReaderOutline />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("52")}
            name={"Trial Balance"}
            Route={"/auth/TrialBalanceTable"}
            Icon={<IoRepeatSharp />}
            visible={[1, 2, 4, 5]}
          />
          <DrawerItem
            show={access.includes("53")}
            name={"Awb Profit Report"}
            Route={"/auth/AwbReports"}
            Icon={<IoRepeatSharp />}
            visible={[1, 2, 4, 5]}
          />
        </Panel>
      </Collapse>
    </div>
  );
}
export default withRouter(SideBar);
