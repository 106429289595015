import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Form,
  Select,
  Button,
  Input,
  message,
  Tabs,
  Table,
  DatePicker,
} from "antd";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import { API, Headers } from "../../Config";
import moment from "moment";
const { TabPane } = Tabs;
function PaymentFormModal(props) {
  console.log("props.selectedRow", props.selectedRow);

  const Auth = useSelector((state) => state.Auth.user);
  const formRef = useRef();
  const [ifBank, setIfBank] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // loadData();
  }, []);

  let TotalPay;
  let TotalBalance;
  let TotalPaid;

  // const loadData = () => {
  //   TotalPay = props.selectedRow[0]?.boxes.reduce(function (tot, arr) {
  //     return Number(tot) + Number(arr.total_charge);
  //   }, 0);
  //   TotalBalance =
  //     props.selectedRow[0].total_pay - props.selectedRow[0]?.paid_amount;
  // };

  const onFinish = (val) => {
    console.log("val =======>", val);
    console.log("TotalPay ======>", TotalPay);
    let box = [];
    props.selectedRow?.boxes?.map((e) => {
      box.push(e.box_number);
    });
    console.log("fffffffffffffffffff", box);
    setLoading(true);
    let obj = {
      // total_pay: TotalPay,
      // paid_amount: Number(val.amount),
      amount: Number(val.amount),
      round_off: Number(val.round_off),
      colln_id: Number(props.selectedRow?.id),
      account_no: val.account_no,
      userid: Number(Auth.id),
      note: "CP Box.Nos:" + box.join("-").toString(),
      cr_or_dr: "Cr",
      payment_date: val.payment_date,
      payment_mode: val.payment_mode,
    };

    // let ID = props.selectedRow[0].id && props.selectedRow[0].id;

    axios({
      method: "post",
      url: API.BASR_URL + API.LEDGER_TRANS_COLLN_ENTRY_ACTIONS,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        message.success("Payment Success");
        setLoading(false);
        props.onCancel();
        props.relaod();
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        console.log(error);
        setLoading(false);
      });
  };

  const onValuesChange = (val) => {
    console.log("onValuesChange ======>", val);
    if (val.mode === "Bank") {
      setIfBank(false);
    } else {
      setIfBank(true);
      // formRef.current?.setFieldsValue({
      //   user: Auth.id,
      // });
    }
  };
  const columns = [
    { title: "Payment ID", dataIndex: "id", key: "id" },
    {
      title: "Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (payment_date) => (
        <div>{payment_date && moment(payment_date).format("DD-MM-YYYY")}</div>
      ),
    },
    {
      title: "User",
      dataIndex: "userid",
      key: "userid",
      render: (userid) => {
        const user = props.users.find((item) => item.id === userid);
        return user ? user.name : "";
      },
    },
    { title: "Mode", dataIndex: "payment_mode", key: "payment_mode" },
    { title: "Amount", dataIndex: "amount", key: "amount" },

    {
      title: "Dr/Cr",
      dataIndex: "cr_or_dr",
      key: "cr_or_dr",
      render: (cr_or_dr) => (
        <span style={{ color: cr_or_dr === "Dr" ? "red" : "green" }}>
          {cr_or_dr}
        </span>
      ),
    },

    // Add more columns as needed
  ];

  return (
    <div>
      <Modal
        visible={props.visible}
        onCancel={props.onCancel}
        width={650}
        footer={false}
        style={{ borderRadius: 10 }}
      >
        <div className="PaymentFormModal-box1">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Make Reciept" key="1">
              <br />
              <div className="PaymentFormModal-txt1">Make Reciept</div>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    padding: "10px",
                    borderRadius: "4px",
                    width: "150px",
                  }}
                >
                  <p style={{ fontWeight: "bold", margin: 0 }}>TOTAL: </p>
                  <p style={{ fontSize: "18px", margin: 0 }}>
                    {props.selectedRow.ledgerTransColln
                      .reduce((total, e) => {
                        if (e.note === "COLLN") {
                          return total + parseFloat(e.amount);
                        }
                        return total;
                      }, 0)
                      .toFixed(2)}
                  </p>
                </div>

                <div
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    padding: "10px",
                    borderRadius: "4px",
                    width: "150px",
                  }}
                >
                  <p style={{ fontWeight: "bold", margin: 0 }}>PAID: </p>
                  <p style={{ fontSize: "18px", margin: 0 }}>
                    {props.selectedRow.ledgerTransColln
                      .reduce((total, e) => {
                        if (
                          e.cr_or_dr === "PAY" &&
                          !isNaN(parseFloat(e.amount))
                        ) {
                          return (
                            total +
                            (parseFloat(e.amount) + parseInt(e.round_off))
                          );
                        }
                        return total;
                      }, 0)
                      .toFixed(2)}
                  </p>
                </div>

                <div
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: "10px",
                    borderRadius: "4px",
                    width: "150px",
                  }}
                >
                  <p style={{ fontWeight: "bold", margin: 0 }}>BALANCE: </p>
                  <p style={{ fontSize: "18px", margin: 0 }}>
                    {props.selectedRow.ledgerTransColln
                      .reduce((balance, e) => {
                        const amount = parseFloat(e.amount);
                        if (e.cr_or_dr === "Dr" && !isNaN(amount)) {
                          return balance + amount;
                        } else if (e.cr_or_dr === "Cr" && !isNaN(amount)) {
                          return balance - amount;
                        }
                        return balance;
                      }, 0)
                      .toFixed(2)}
                  </p>
                </div>
              </div>

              <br />
              <div className="">
                <Form
                  ref={formRef}
                  onFinish={onFinish}
                  onValuesChange={onValuesChange}
                  initialValues={{}}
                >
                  <div className="PaymentFormModal-txt2">Reciept Date</div>
                  <Form.Item
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    name="payment_date"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <DatePicker
                      placeholder="Pick a date"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  {/* <div className="PaymentFormModal-txt2">User</div>
                  <Form.Item name="user">
                    <Select style={{ width: "100%" }} placeholder="Select User">
                      {props.users.map((item, index) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item> */}
                  <div className="PaymentFormModal-txt2">Account</div>
                  <Form.Item
                    name="account_no"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) => {
                        const accountNo = option.value.toLowerCase();
                        const accountName = option.children
                          .toString()
                          .toLowerCase();
                        return (
                          accountNo.includes(input.toLowerCase()) ||
                          accountName.includes(input.toLowerCase())
                        );
                      }}
                      style={{ width: "100%" }}
                      placeholder="Select Account"
                    >
                      {props.accounts.map((item, index) => (
                        <Select.Option
                          key={item.account_no}
                          value={item.account_no}
                        >
                          {item.account_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/* <div className="PaymentFormModal-txt2">Payment Mode</div>
                  <Form.Item name="payment_mode">
                    <Select style={{ width: "100%" }} placeholder="Select Mode">
                      <Select.Option key="Cash">Cash</Select.Option>
                      <Select.Option key="Card">Card</Select.Option>
                      <Select.Option key="Bank">Bank</Select.Option>
                    </Select>
                  </Form.Item> */}
                  {/* <div className="PaymentFormModal-txt2">Select Bank</div>
              <Form.Item name="bank">
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Bank"
                  disabled={ifBank}
                >
                  <Select.Option key="1">Bank 1</Select.Option>
                  <Select.Option key="2">Bank 2</Select.Option>
                </Select>
              </Form.Item>   */}
                  <div className="PaymentFormModal-txt2">Amount</div>
                  <Form.Item
                    name="amount"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <Input />
                  </Form.Item>
                  <div className="PaymentFormModal-txt2">Round Off</div>
                  <Form.Item
                    name="round_off"
                    rules={[{ required: false, message: "required" }]}
                  >
                    <Input />
                  </Form.Item>
                  <div className="PaymentFormModal-txt2">Remarks</div>
                  <Form.Item name="note">
                    <Input />
                  </Form.Item>
                  <Row>
                    <Col sm="6" xs="12">
                      <Button
                        block
                        onClick={() => props.onCancel()}
                        size="large"
                      >
                        Close
                      </Button>
                    </Col>
                    <Col sm="6" xs="12">
                      <Form.Item>
                        <Button
                          type="primary"
                          block
                          size="large"
                          htmlType="submit"
                          loading={loading}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </TabPane>
            <TabPane tab="Reciept Details" key="2">
              <Table
                dataSource={props.selectedRow.ledgerTransColln}
                columns={columns}
                pagination={false}
                size="middle"
              />
            </TabPane>
          </Tabs>
        </div>
      </Modal>
    </div>
  );
}

export default PaymentFormModal;
