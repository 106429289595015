import { useState, useRef, useEffect } from "react";
import {
  Form,
  Input,
  Table,
  Button,
  Space,
  DatePicker,
  Menu,
  Checkbox,
  Dropdown,
  Select,
  Row,
  Col,
  InputNumber,
  message,
  Modal,
} from "antd";
import "./style.css";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import { API, Headers } from "../../Config";
import { useSelector } from "react-redux";

const FormModal = (props) => {
  const Auth = useSelector((state) => state.Auth.user);
  const formRef = useRef();
  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  let data = [];
  if (props.mode === "post") {
    data = [
      {
        key: 1,
        account_no: null,
        description: "",
        amount_cr: 0,
        amount_dr: 0,
      },
    ];
  } else {
    if (props.item.id) {
      const updatedPurchaseItems = props.item.voucherDetails.map((item) => {
        return {
          key: item.key,
          account_no: item.account_no,
          description: item.description,
          amount_cr: item.amount_cr,
          amount_dr: item.amount_dr,
        };
      });
      data = updatedPurchaseItems;
      console.log("updatedPurchaseItems", data);
    }
    // handleTotal();
  }

  const [dataSource, setDataSource] = useState(data);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [totalCr, setTotalCr] = useState(
    props.mode == "put" ? props.item.cr_total : 0
  );
  const [totalDr, setTotalDr] = useState(
    props.mode == "put" ? props.item.dr_total : 0
  );

  const tableRef = useRef(null);
  useEffect(() => {
    fetchData(page, take);
    handleTotal();
  }, [totalDr, dataSource]);

  //
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const onFinish = (values) => {
    console.log("dataSource", dataSource);
    if (props.page_mode === "JV") {
      const crTotal = Number(values.cr_total);
      const drTotal = Number(values.dr_total);

      if (crTotal !== drTotal) {
        console.log("CR Total and DR Total must be equal");
        message.info("CR Total and DR Total must be equal");
        return; // Exit the function if validation fails
      }
    }
    setLoading(true);
    let obj = {
      voucher_date: values.voucher_date,
      voucher_type: props.page_mode,
      account_no: values.account_no ?? null,
      description: values.description,
      userid: Number(Auth.id),
      cr_total: Number(totalCr),
      dr_total: Number(totalDr),
      voucherItems: dataSource,
    };
    console.log("obj", obj);
    let id = props.item.id;
    let URL =
      props.mode === "post"
        ? API.VOUCHER_ACTIONS + props.page_mode
        : API.VOUCHER_ACTIONS + id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          props.onchange();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error("Oops.Something went wrong save");
      });
  };

  const handleTotal = (_, values) => {
    if (props.page_mode === "JV") {
      const newDataSource = [...dataSource];
      newDataSource.forEach((val, index) => {
        if (val.amount_dr > 1 && val.amount_cr > 1) {
          // Both debit and credit amounts are entered
          // Reset the values to 0 for the conflicting row
          newDataSource[index].amount_dr = 0;
          newDataSource[index].amount_cr = 0;
        } else if (val.amount_dr > 1) {
          // Only debit amount is entered
          // Set credit amount to 0
          newDataSource[index].amount_cr = 0;
        } else if (val.amount_cr > 1) {
          // Only credit amount is entered
          // Set debit amount to 0
          newDataSource[index].amount_dr = 0;
        }
      });
      setDataSource(newDataSource);
    }
    const totalCr = dataSource.reduce((acc, item) => {
      return acc + item.amount_cr;
    }, 0);
    const totalDr = dataSource.reduce((acc, item) => {
      return acc + renderDebitAmount(item);
    }, 0);
    setTotalCr(totalCr);
    setTotalDr(totalDr);
    form.setFieldsValue({
      cr_total: totalCr,
      dr_total: totalDr,
    });
  };

  const handleAddProduct = () => {
    const newDataSource = [...dataSource];
    newDataSource.push({
      slno: newDataSource.length + 1,
      key: newDataSource.length + 1,
      account_no: null,
      description: "",
      amount_cr: 0,
      amount_dr: 0,
    });
    setDataSource(newDataSource);
  };

  const handleRemoveProduct = (key) => {
    let deleteList = [...dataSource];
    let keys = deleteList.findIndex((value) => value.key === key);
    deleteList.splice(keys, 1);
    setDataSource(deleteList);
  };

  // const handleChangeValueSet = (value, key, field) => {
  //   console.log(value, "===val", key, "keyy", field, "==field");
  //   const newDataSource = [...dataSource];
  //   newDataSource.forEach((val) => {
  //     if (val.key === key) {
  //       val[field] = value;
  //     }
  //   });
  //   setDataSource(newDataSource);
  // };

  const renderDebitAmount = (record) => {
    const currentPaymentInfo = paymentinfo.find((item) =>
      item.awbPaymentDetails.some(
        (detail) => detail.account_no === record.account_no
      )
    );

    const correspondingAmount = currentPaymentInfo
      ? currentPaymentInfo.awbPaymentDetails.find(
          (detail) => detail.account_no === record.account_no
        )?.amount
      : null;

    return correspondingAmount !== null
      ? correspondingAmount
      : record.amount_dr;
  };
  const handleChangeValueSet = (value, key, field) => {
    console.log(value, "===val", key, "keyy", field, "==field");
    const newDataSource = [...dataSource];
    newDataSource.forEach((val) => {
      if (val.key === key) {
        val[field] = value;
        if (field === "account_no") {
          const amount = renderDebitAmount(val);
          val.amount_dr = amount;
        }
      }
    });
    setDataSource(newDataSource);
  };

  const fetchData = async (p, t) => {
    axios
      .get(
        API.BASR_URL + API.AWB_PAYMENT_LIST + `?order=DESC&page=${p}&take=${t}`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          let res = response.data;
          setPaymentData(res?.data);
        }
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
      });
  };
  let paymentinfo = paymentData.map((item) => item);

  const columns = [
    {
      title: "#",
      dataIndex: "slno",
      key: "slno",
      width: "30px",
      align: "center",
      visible: true,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Account",
      dataIndex: "account_no",
      key: "account_no",
      visible: true,
      width: "300px",
      fixed: "left",
      render: (text, record) => {
        return (
          <Form.Item
            style={{ padding: 0, margin: 0 }}
            name={["list", record.key, "account_no"]}
            initialValue={text}
            rules={[{ required: true, message: "required" }]}
          >
            <Select
              bordered={false}
              className="CustomerForm-Select"
              showSearch
              allowClear
              filterOption={(input, option) => {
                const accountNo = option.value.toLowerCase();
                const accountName = option.children.toString().toLowerCase();
                return (
                  accountNo.includes(input.toLowerCase()) ||
                  accountName.includes(input.toLowerCase())
                );
              }}
              style={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "0px",
              }}
              value={record.account_no}
              onChange={(value) => {
                handleChangeValueSet(value, record.key, "account_no");
              }}
            >
              {props.accounts.map((item) => (
                <Select.Option key={item.account_no} value={item.account_no}>
                  {item.account_no}- {item.account_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      },
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      visible: true,
      width: "250px",
      render: (text, record) => (
        <Input.TextArea
          style={{ width: "100%" }}
          autoSize={{ minRows: 1, maxRows: 6 }}
          bordered={false}
          value={record.description}
          onChange={(e) =>
            handleChangeValueSet(e.target.value, record.key, "description")
          }
          required={true}
        />
      ),
    },
    {
      title: "Debit Amount",
      dataIndex: "amount_dr",
      key: "amount_dr",
      visible: true,
      width: "250px",
      render: (text, record) => {
        const amount = renderDebitAmount(record);
        console.log("Amount from renderDebitAmount:", amount);
        return (
          <InputNumber
            style={{ width: "100%" }}
            bordered={false}
            value={amount}
            disabled
            onChange={(e) => {
              handleChangeValueSet(amount, record.key, "amount_dr");
              handleTotal();
            }}
          />
        );
      },
    },
    {
      title: "Credit Amount",
      dataIndex: "amount_cr",
      key: "amount_cr",
      visible: props.columnsVisible.amount_cr,
      width: "150px",
      render: (text, record) => {
        return (
          <InputNumber
            style={{ width: "100%" }}
            bordered={false}
            value={record.amount_cr}
            onChange={(e) => {
              handleChangeValueSet(e, record.key, "amount_cr");
              handleTotal();
            }}
          />
        );
      },
    },

    {
      title: " ",
      key: "action",
      visible: true,
      align: "center",
      width: "30px",
      fixed: "right",
      render: (text, record) => (
        <Space size="middle">
          <DeleteOutlined
            onClick={() => {
              if (dataSource.length > 1) {
                handleRemoveProduct(record.key);
              }
            }}
          />
        </Space>
      ),
    },
  ];

  const renderSummary = (form) => {
    return (
      <>
        <Table.Summary.Row
          className="my-summary-row"
          style={{ height: "50px", alignItems: "left" }}
        >
          <Table.Summary.Cell
            className="cell"
            fixed="left"
            scroll={{ x: 0, y: 0 }}
          ></Table.Summary.Cell>
          <Table.Summary.Cell
            className="cell"
            fixed="left"
            scroll={{ x: 0, y: 0 }}
          >
            <Button
              style={{
                textDecoration: "underline",
                background: "transparent",
                border: "none",
                cursor: "pointer",
              }}
              size="small"
              bordered={false}
              onClick={handleAddProduct}
            >
              Add New
            </Button>
          </Table.Summary.Cell>

          <Table.Summary.Cell className="cell">
            <b>Total</b>
          </Table.Summary.Cell>
          <Table.Summary.Cell
            className={`cell ${!props.columnsVisible.amount_dr && "hidden"}`}
            align="center"
          >
            <Form.Item
              form={form}
              name="dr_total"
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              <b style={{ paddingLeft: "9px", textAlign: "left" }}>
                {Number(totalDr).toFixed(2)}
              </b>
            </Form.Item>
          </Table.Summary.Cell>
          <Table.Summary.Cell
            className={`cell ${!props.columnsVisible.amount_cr && "hidden"}`}
            align="center"
          >
            <Form.Item
              form={form}
              name="cr_total"
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              <b style={{ paddingLeft: "9px", textAlign: "left" }}>
                {Number(totalCr).toFixed(2)}
              </b>
            </Form.Item>
          </Table.Summary.Cell>

          <Table.Summary.Cell
            className="cell"
            fixed="right"
          ></Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  return (
    <>
      <div>
        <Modal
          title={props.columnsVisible.page}
          centered
          open={props.visible}
          onOk={() => props.onClose()}
          onCancel={() => props.onClose()}
          width={1000}
          footer={false}
          style={{ borderRadius: 10 }}
        >
          <Form
            form={form}
            ref={formRef}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={handleTotal}
            initialValues={
              props.mode === "post"
                ? {
                    voucher_date: moment(),
                    amount: 0.0,
                    currency_id: props.defultcurrency,
                  }
                : {
                    voucher_date: props.item && moment(props.item.voucher_date),
                    account_no: props.item && props.item.account_no,
                    description: props.item && props.item.description,
                    cr_total: props.item && props.item.cr_total,
                    dr_total: props.item && props.item.dr_total,
                  }
            }
            hideRequiredMark
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                {props.page_mode === "JV" ? null : (
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    labelCol={{
                      span: 7,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    name="account_no"
                    label="Account"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <Select
                      size="small"
                      placeholder="Select account"
                      showSearch
                      allowClear
                      style={{ borderRadius: "0px" }}
                      filterOption={(input, option) => {
                        const accountNo = option.value.toLowerCase();
                        const accountName = option.children
                          .toString()
                          .toLowerCase();
                        return (
                          accountNo.includes(input.toLowerCase()) ||
                          accountName.includes(input.toLowerCase())
                        );
                      }}
                    >
                      {props.accounts.map((item, index) => (
                        <Select.Option
                          key={item.account_no}
                          value={item.account_no}
                        >
                          {item.account_no} - {item.account_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                <Form.Item
                  style={{ marginBottom: "12px" }}
                  labelCol={{
                    span: 7,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  name="description"
                  label="Description"
                  rules={[{ required: true, message: "required" }]}
                >
                  <Input.TextArea
                    required={true}
                    rows={2}
                    size="small"
                    placeholder="Enter Remarks"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  style={{ marginBottom: "0px" }}
                  labelCol={{
                    span: 7,
                  }}
                  wrapperCol={{
                    span: 7,
                  }}
                  name="voucher_date"
                  label="Voucher Date"
                  rules={[{ required: true, message: "required" }]}
                >
                  <DatePicker
                    size="small"
                    format={dateFormat}
                    placeholder="Pick a date"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Table
              initialValue={{}}
              className="my-table"
              dataSource={dataSource}
              columns={columns.filter((column) => column.visible)}
              pagination={false}
              size="small"
              bordered
              summary={() => renderSummary(form)}
              rowKey="key"
              ref={tableRef}
            />

            <Row justify="end">
              <Col sm="6" xs="12" style={{ padding: "10px" }}>
                <Button width="30%" onClick={() => props.onClose()} block>
                  Cancel
                </Button>
              </Col>
              <Col sm="6" xs="12" style={{ padding: "10px" }}>
                <Button
                  width="30%"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  block
                >
                  {props.mode === "post" ? "Save" : "Update"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default FormModal;
