import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clear } from "../../Redux/Slices/CollectionSlice";
import PageHeader from "../../Routes/PageHeader";
import FormModal from "./FormModal";
import DataTable from "./DataTable";
import axios from "axios";
import { message } from "antd";
import { API, Headers } from "../../Config";
import LoadingBox from "../../Components/LoadingBox";
import DetailsMoal from "./DetailsMoal";
import NewFormModal from "./NewFormModal";
import ShareBillModal from "./ShareBillModal";
import TabHeader from "../../Routes/TabHeader";
import PaymentFormModal from "./PaymentFormModal";
import "./style.css";
function CollectionScreen() {
  const dispatch = useDispatch();
  const Auth = useSelector((state) => state.Auth.user);
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState(false);
  const [form, setform] = useState(false);
  const [details, setdetails] = useState(false);
  const [share, setShare] = useState(false);
  const [mode, setMode] = useState("post");
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(10);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [item, setItem] = useState({});

  const [customers, setcustomers] = useState([]);
  const [agents, setagents] = useState([]);
  const [users, setusers] = useState([]);
  const [servicetype, setservicetype] = useState([]);
  const [boxtype, setboxtype] = useState([]);
  const [countries, setcountries] = useState([]);
  const [recievers, setRecievers] = useState([]);
  const [senders, setSenders] = useState([]);

  const [selectedRow, setSelectedRow] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [payModal, setPayModal] = useState(false);

  useEffect(() => {
    loaddata(page, take);
    CustomersDropdown();
    AgentsDropdown();
    ServicetypeDropdown();
    BoxtypeDropdown();
    CountriesDropdown();
    RecieversDropdown();
    SendersDropdown();
    UserDropdown();
    AccountsDropdown();
  }, []);

  // let URL = API.PICKUPCOLLECTIONS_LIST;
  let URL =
    Auth.id === "1"
      ? API.PICKUPCOLLECTIONS_LIST
      : API.PICKUPCOLLECTIONS_ACTIONS_BY_ID + `${Number(Auth.id)}`;

  const loaddata = (p, t) => {
    axios
      .get(API.BASR_URL + URL + `?order=DESC&page=${p}&take=${t}`, {
        headers: Headers(),
      })
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          console.log(data);
          setData(data.data);
          setMeta(data.meta);
          message.success("Success");
        } else {
          message.error("Oops.Something went wrong");
        }
        setLoading(false);
        setIndicator(false);
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        setIndicator(false);
        setLoading(false);
      });
  };

  const CustomersDropdown = () => {
    axios
      .get(API.BASR_URL + API.CUSTOMERS_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setcustomers(res.data);
      })
      .catch(function (error) {
        message.error("Customers dropdown faild");
      });
  };
  const AccountsDropdown = () => {
    axios
      .get(API.BASR_URL + API.CHART_OF_ACCOUNTS_LIST + `/child_accounts`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setAccounts(res.data);
      })
      .catch(function (error) {
        message.error("Customers dropdown faild");
      });
  };

  const AgentsDropdown = () => {
    axios
      .get(API.BASR_URL + API.AGENTS_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setagents(res.data);
      })
      .catch(function (error) {
        message.error("agents dropdown faild");
      });
  };

  const ServicetypeDropdown = () => {
    axios
      .get(API.BASR_URL + API.SERVICE_TYPE_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setservicetype(res.data);
      })
      .catch(function (error) {
        message.error("Service type dropdown faild");
      });
  };

  const BoxtypeDropdown = () => {
    axios
      .get(API.BASR_URL + API.BOXTYPE_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setboxtype(res.data);
      })
      .catch(function (error) {
        message.error("Service type dropdown faild");
      });
  };

  const CountriesDropdown = () => {
    axios
      .get(API.BASR_URL + API.COUNTRIES_LIST + `/list`, {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setcountries(res.data);
      })
      .catch(function (error) {
        message.error("Countries dropdown faild");
      });
  };
  const RecieversDropdown = () => {
    axios
      .get(API.BASR_URL + API.RECIVERS_LIST + "/list", {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) setRecievers(res.data);
      })
      .catch(function (error) {
        message.error("Recievers Dropdown  faild");
      });
  };
  const SendersDropdown = () => {
    axios
      .get(API.BASR_URL + API.SENDERS_LIST + "/list", {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) {
          setSenders(res.data);
        }
      })
      .catch(function (error) {
        message.error("Senders Dropdown  faild");
      });
  };

  const UserDropdown = () => {
    axios
      .get(API.BASR_URL + API.USERS_LIST + "/list", {
        headers: Headers(),
      })
      .then(function (res) {
        if (res.status === 200) {
          setusers(res.data);
        }
      })
      .catch(function (error) {
        message.error("Senders Dropdown  faild");
      });
  };

  const pagination = (page, take) => {
    window.scrollTo(0, 0);
    setIndicator(true);
    setPage(page);
    setTake(take);
    loaddata(page, take);
  };

  const relaod = () => {
    loaddata(page, take);
    setMode("post");
    setItem({});
    setform(false);
  };

  const AddItem = () => {
    setMode("post");
    setItem({});
    setform(true);
  };

  const UpdateItem = (item) => {
    setMode("put");
    setItem(item);
    dispatch(clear());
    setform(true);
  };

  const showDetails = (item) => {
    setItem(item);
    setdetails(!details);
  };
  const showPayment = (item) => {
    // setItem(item);
    setPayModal(true);
    setSelectedRow(item);
    // setdetails(!details);
    console.log(12131321321321, item);
  };

  const shareBill = (item) => {
    setItem(item);
    setShare(true);
  };

  const DeleteItem = (item) => {
    setIndicator(true);

    let obj = {
      is_delete: true,
    };

    axios({
      method: "put",
      url: API.BASR_URL + API.PICKUPCOLLECTIONS_ACTIONS + `colln/` + item.id,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        setIndicator(false);
        message.success("Deleted Successfully");
        relaod();
      })
      .catch(function (error) {
        setIndicator(false);
        message.error("Oops.Something went wrong");
        console.log(error);
      });
  };

  const Search = (val) => {
    console.log(
      ",,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,",
      API.BASR_URL + API.PICKUPCOLLECTIONS_ACTIONS + `details/search/${val}/BOX`
    );
    setIndicator(true);
    console.log(
      API.BASR_URL + API.PICKUPCOLLECTIONS_ACTIONS + `details/search/${val}/BOX`
    );
    axios
      .get(
        API.BASR_URL +
          API.PICKUPCOLLECTIONS_ACTIONS +
          `details/search/${val}/BOX`,
        {
          headers: Headers(),
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          console.log("edfdsffdsfdsfdsf", data);
          setData(data);
          message.success("Success");
          setIndicator(false);
        }
      })
      .catch(function (error) {
        message.error(`Box111 ${val} Not Found`);
        setIndicator(false);
        console.log(error);
      });
  };

  const onSelect = (item) => {
    setSelectedRow(item);
  };

  return (
    <div>
      <PageHeader
        PageName={"Collection"}
        breadScrum={"Admin /Collection"}
        RightButton={"New Collection"}
        RightClcik={() => AddItem()}
        SearchBar={(val) => Search(val)}
        loader={indicator}
      />
      <TabHeader
        setPayModal={() => setPayModal(true)}
        selectedRow={selectedRow}
      />
      {loading ? (
        <LoadingBox />
      ) : (
        <div>
          <DataTable
            meta={meta}
            data={data}
            update={(item) => UpdateItem(item)}
            delete={(item) => DeleteItem(item)}
            details={(item) => showDetails(item)}
            payment={(item) => showPayment(item)}
            share={(item) => shareBill(item)}
            onSelect={(item) => onSelect(item)}
            PageOnchange={(page, take) => pagination(page, take)}
          />
        </div>
      )}
      {/* {form ? (
            <FormModal
              item={item}
              customers={customers}
              agents={agents}
              users={users}
              servicetype={servicetype}
              boxtype={boxtype}
              countries={countries}
              recievers={recievers}
              senders={senders}
              mode={mode}
              visible={form}
              onchange={() => relaod()}
              onClose={() => setform(false)}
            />
          ) : null} */}
      {form ? (
        <NewFormModal
          item={item}
          customers={customers}
          agents={agents}
          users={users}
          servicetype={servicetype}
          boxtype={boxtype}
          countries={countries}
          recievers={recievers}
          senders={senders}
          mode={mode}
          visible={form}
          onchange={() => relaod()}
          onCancel={() => setform(false)}
        />
      ) : null}
      {details ? (
        <DetailsMoal
          item={item}
          visible={details}
          onClose={() => showDetails({})}
        />
      ) : null}
      {share ? (
        <ShareBillModal
          item={item}
          visible={share}
          onCancel={() => setShare(false)}
        />
      ) : null}
      {payModal ? (
        <PaymentFormModal
          users={users}
          selectedRow={selectedRow}
          visible={payModal}
          accounts={accounts}
          relaod={() => relaod()}
          onCancel={() => setPayModal(false)}
        />
      ) : null}
    </div>
  );
}
export default CollectionScreen;
