import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  Button,
  DatePicker,
  message,
  Tooltip,
  notification,
  Popconfirm,
} from "antd";
import { Table, Row, Col } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import {
  IoIosClose,
  IoMdRemoveCircleOutline,
  IoIosAddCircle,
} from "react-icons/io";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import { TiTickOutline } from "react-icons/ti";
import { BiReset } from "react-icons/bi";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { API, Headers, CurrancyRate } from "../../Config";
import { save, clear } from "../../Redux/Slices/CollectionSlice";
import LoadingBox from "../../Components/LoadingBox";
import BoxItemsForm from "./BoxItemsForm";
import SearchModal from "./SearchModel";
import styles from "./styles";
import PincodeSearchModel from "./PincodeSearchModel";
import RATES from "../../Config/exchangeRates";
import { getExchangeRates } from "../../Config/exchangeRates";
import { POST } from "../../Config/ApiRequests";

function NewFormModal(props) {
  var width = window.innerWidth;
  const { Option } = Select;
  const formRef = useRef();
  const dispatch = useDispatch();
  const Auth = useSelector((state) => state.Auth.user);
  const Collection = useSelector((state) => state.Collection);
  const [obje, setObje] = useState(Collection.data);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [uuid, setUuid] = useState(uuidv4());
  const [expand, setexpand] = useState(0);
  const [editrate, setEditRate] = useState(false);

  const [expanded, setExpanded] = useState("panel1");

  const [INR, setInr] = useState(0);
  const [USD, setUsd] = useState(0);
  const [SAR, setSar] = useState(0);

  //customer section
  const [prefix, setPrefix] = useState("PH");
  const [search, setSearch] = useState("");
  const [searchModal, setSearchModal] = useState(false);
  const [data, setData] = useState([]);
  const [searchData, setsearchData] = useState([]);

  const [pincode, setPincode] = useState("");
  const [searchPincodeModal, setSearchPincodeModal] = useState(false);
  const [pincodeData, setPincodeData] = useState([]);

  const [isLoading2, setIsLoading2] = useState(false);
  const [exchangeRates, setexchangeRates] = useState([]);
  const [rates, setRates] = useState({});
  const [meta, setMeta] = useState({});

  useEffect(() => {
    // GetCurrancy();
    getExchangeRates(1, 10);
  }, []);

  const getExchangeRates = async (_page, _take) => {
    try {
      setIsLoading2(true);
      let api = API.EXCHANGE_RATE_LIST;
      let obj = {
        page: _page,
        take: _take,
      };
      const excData = await POST(api, obj);
      if (excData?.status) {
        setexchangeRates(excData?.data[0]);
        setRates(excData?.data[0]);
        console.log("excData?.data?.sar_inr === ", excData?.data);
        setInr(excData?.data[0]?.sar_inr);
        setUsd(excData?.data[0]?.sar_usd);
        setSar(excData?.data[0]?.inr_sar);
        setMeta(excData?.meta);
        setIsLoading2(false);
      } else {
        message.error("No data found");
        setIsLoading2(false);
      }
    } catch (err) {
      message.error("Oops.Something went");
      setIsLoading2(false);
    }
  };

  // const GetCurrancy = async () => {
  //   let INR = await CurrancyRate("SAR", "INR");
  //   setInr(INR);
  //   let USD = await CurrancyRate("SAR", "USD");
  //   setUsd(USD);
  //   let SAR = await CurrancyRate("INR", "SAR");
  //   setSar(SAR);
  // };
  console.log("INR ---> ", INR);
  console.log("USD ---> ", USD);
  console.log("SAR ---> ", SAR);
  // changes by JIS - 8/4/2024
  const GetCurrancy = async () => {
    // let INR = await CurrancyRate("SAR_INR");
    const INR = await getExchangeRates("SAR_INR");
    console.log("INR ---> ", INR);
    setInr(INR);
    // let INR = RATES.SAR_INR;
    // setInr(INR);
    // let USD = await CurrancyRate("SAR", "USD");
    // let USD = RATES.SAR_USD;
    // let USD = await CurrancyRate("SAR_USD");

    const USD = await getExchangeRates("SAR_USD");
    console.log("USD ---> ", USD);
    setUsd(USD);
    // setUsd(USD);
    // let SAR = await CurrancyRate("INR", "SAR");
    // let SAR = RATES.INR_SAR;
    // let SAR = await CurrancyRate("INR_SAR");
    const SAR = await getExchangeRates("INR_SAR");
    console.log("SAR ---> ", SAR);
    setSar(SAR);
    // setSar(SAR);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleTotal = (action, values) => {
    const rowsCopy = [...values.boxs];

    let numberBoxes = values.boxs && values.boxs.length;
    let totalCargo = 0;
    let totalPacking = 0;
    let totalDuty = 0;
    let totalOther = 0;
    let totalWeight = 0;
    let totalAmount = 0;
    let L = 0;
    let B = 0;
    let H = 0;

    values.boxs.forEach((fields, index) => {
      let chargableWeight = 0;
      let ChargableRate = 0;
      let totalValue = 0;
      let totalValueINR = 0;
      fields.uuid = props.uuid && props.uuid;
      let number = `${Auth.id}${moment().unix()}`;
      let serviceCheck = fields.service_type
        ? props.servicetype.filter((i) => i.id === fields.service_type)
        : 0;

      if (
        !fields.total_value_edit &&
        fields.boxitems &&
        fields.boxitems.length
      ) {
        fields.boxitems.forEach((boxitems) => {
          let qty = boxitems ? Number(boxitems.quantity) : 0;
          let value = boxitems ? Number(boxitems.value) : 0;
          totalValue = Number(totalValue) + Number(Number(qty) * Number(value));
        });
      } else {
        totalValue = fields.total_value;
      }

      // set total_value_inr_edit to true for mobile edit
      // fields.total_value_inr_edit =
      //   props.mode === "put" &&
      //   props.item &&
      //   props.item.created_from === "mobile"
      //     ? true
      //     : fields.total_value_inr_edit;

      if (!fields.total_value_inr_edit) {
        fields.total_value_inr = Number(
          Number(totalValue) * Number(INR)
        ).toFixed(2);
      } else {
        totalValueINR = fields.total_value_inr;
        totalValue = Number(Number(totalValueINR) * Number(SAR)).toFixed(2);
      }

      fields.box_number = fields.box_number ? fields.box_number : number;
      if (fields.size !== 0) {
        let BOXTYPE = fields.size
          ? props.boxtype.filter((i) => i.id === fields.size)[0]
          : null;
        L = BOXTYPE ? Number(BOXTYPE.length).toFixed(1) : 0;
        B = BOXTYPE ? Number(BOXTYPE.breadth).toFixed(1) : 0;
        H = BOXTYPE ? Number(BOXTYPE.height).toFixed(1) : 0;
      } else {
        L = fields.length ? fields.length : null;
        B = fields.breadth ? fields.breadth : null;
        H = fields.height ? fields.height : null;
      }

      let SERVICE = serviceCheck.length ? serviceCheck[0].value : 0;
      let WEIGHT = fields.weight ? Number(fields.weight).toFixed(1) : 0;
      let packing = fields.packing_charge ? fields.packing_charge : 0;
      let duty = fields.duty ? fields.duty : 0;
      let other = fields.other_charges ? fields.other_charges : 0;
      let rate = fields.rate ? fields.rate : 0;
      let tax =
        serviceCheck && Number(serviceCheck[0].vat_percentage) > 0
          ? (Number(serviceCheck[0].vat_percentage) / 100).toFixed(2)
          : 0; //added tax
      // let rate =
      //   serviceCheck && serviceCheck[0].rate_per_kg
      //     ? serviceCheck[0].rate_per_kg
      //     : fields.rate;
      // if (editrate) rate = fields.rate;
      // fields.rate = rate;
      // end values form form
      let volumeWeight = Number((L * B * H) / SERVICE).toFixed(1);

      //set chargable_weight_edit to true for mobile edit
      fields.chargable_weight_edit =
        props.mode === "put" &&
        props.item &&
        props.item.created_from === "mobile"
          ? true
          : fields.chargable_weight_edit;

      if (!fields.chargable_weight_edit) {
        chargableWeight =
          Number(volumeWeight) >= Number(WEIGHT) ? volumeWeight : WEIGHT;
      } else {
        chargableWeight = fields.chargable_weight;
      }
      totalWeight = Number(
        Number(totalWeight) + Number(chargableWeight)
      ).toFixed(2);
      totalCargo = Number(totalCargo) + Number(fields.cargo_cahrge);
      totalPacking = Number(totalPacking) + Number(fields.packing_charge);
      totalDuty = Number(totalDuty) + Number(fields.duty);
      totalOther = Number(totalOther) + Number(fields.other_charges);
      ChargableRate = rate ? Number(rate) * Number(chargableWeight) : 0;
      let totalValueUSD = Number(Number(totalValue) * Number(USD)).toFixed(2);

      //set fields
      fields.length = L;
      fields.breadth = B;
      fields.height = H;
      fields.volume_weight = Number(volumeWeight) ? volumeWeight : 0;
      fields.chargable_weight = chargableWeight;
      fields.total_value = totalValue;
      fields.total_value_usd = Number(totalValueUSD);

      fields.total_charge = (
        Number(packing) +
        Number(duty) +
        Number(other) +
        Number(ChargableRate) +
        Number(tax)
      ).toFixed(2);
      totalAmount = Number(
        Number(totalAmount) +
          Number(packing) +
          Number(duty) +
          Number(other) +
          Number(ChargableRate) +
          Number(tax)
      );
      rowsCopy.splice(index, 1, fields);
      formRef.current.setFieldsValue({ boxs: rowsCopy });

      //end set fields
    });
    //set common fields
    formRef.current.setFieldsValue({
      number_boxes: numberBoxes,
      total_weight: totalWeight,
      total_amount: totalAmount.toFixed(2),
      status: 2,
      collected_by: Auth.id,
      created_from:
        props.mode === "post" ? "web" : props.item && props.item.created_from,
    });
    let obj = {
      cargo_charge: totalCargo,
      packing_charge: totalPacking,
      other_charge: totalOther,
      duty: totalDuty,
      ...values,
    };

    JoinDATA(obj);
    // setData(obj);
  };

  const JoinDATA = (values) => {
    let objs = {
      uuid:
        Collection.data && Collection.data.uuid ? Collection.data.uuid : uuid,
    };
    var obj = {
      ...Collection.data,
      ...values,
      ...objs,
    };
    setObje(obj);
    setTimeout(() => {
      dispatch(save(obj));
    }, 10);
  };

  const onFinishFailed = (error) => {
    error.errorFields.map((errors) => {
      notification.open({
        message: "ERROR",
        description: `${errors.name}`,
      });
    });
  };
  let branch = {
    branch_id: Number(Auth.branch_id),
  };
  const onFinish = (data) => {
    setLoading(true);
    var obj = {
      data: {
        ...obje,
        ...data,
        ...branch,
      },
    };
    let URL =
      props.mode === "post"
        ? "pickupCollectionsNew/createCollection"
        : API.PICKUPCOLLECTIONS_ACTIONS + props.item.id;
    axios({
      method: props.mode,
      url: API.BASR_URL + URL,
      headers: Headers(),
      data: obj,
    })
      .then(function (response) {
        if ([200, 201].includes(response.status)) {
          message.success("Success");
          setLoading(false);
          dispatch(clear());
          props.onCancel();
          props.onchange();
        }
      })
      .catch(function (error) {
        message.error("Oops.Something went wrong");
        setLoading(false);
        console.log(error);
        notification.open({
          message: "ERROR",
          description: `${error.response.data.message}`,
        });
      });
  };

  //customer info section

  const loaddata = () => {
    setLoading2(true);
    message.loading("Loading....");

    let URL =
      prefix === "REF"
        ? API.BASR_URL + API.PICKUP_COLLECTION_REQUEST_LIST + `/list/${search}`
        : API.BASR_URL +
          API.PICKUPCOLLECTIONS_LIST +
          `/details/search/${search}/${prefix}`;

    if (search) {
      axios
        .get(URL, {
          headers: Headers(),
        })
        .then(function (response) {
          if (response.status === 200) {
            let data = response.data;
            setsearchData(data);

            message.success("Success");
          } else {
            message.error("Oops.Something went wrongs");
          }

          setLoading2(false);
        })
        .catch(function (error) {
          message.error("Oops.Something went wrong....123");
          setLoading2(false);
        });

      if (prefix === "REF") {
        console.log("setsearchData ===>", searchData);
        console.log("setsearchData ===>", searchData[0].id);
        let ReqID = searchData && searchData[0].id;
        let obj = {
          active: false,
        };
        let URL = API.BASR_URL + API.PICKUP_COLLECTION_REQUEST_ACTIONS + ReqID;
        axios({
          method: "put",
          headers: Headers(),
          url: URL,
          data: obj,
        })
          .then(function (response) {
            if (response.status === 200) {
              console.log(response.data);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  const selectChange = (e) => {
    setPrefix(e);
    console.log("prefixSelector===>", e);
  };
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 150,
        }}
        value={prefix}
        onChange={selectChange}
        defaultValue={"PH"}
      >
        <Option value="PH">Phone</Option>
        <Option value="BOX">Box Number</Option>
        <Option value="REF">Pickup Request</Option>
      </Select>
    </Form.Item>
  );

  const handleDataChange = (event) => {
    event.preventDefault();
    setSearch(event.target.value);
    console.log("handleDataChange====>", event.target.value);
  };
  const onSearchPickup = (event, val) => {
    event.preventDefault();
    setSearchModal(true);
    loaddata(prefix, search);
  };

  const chooseSenderAndReciverData = (item) => {
    setSearchModal(false);

    if (item) {
      formRef.current?.setFieldsValue({
        sender_name: item.sender_name,
        sender_address: item.sender_address,
        sender_phone: item.sender_phone,
        sender_phone_alt: item.sender_phone_alt,
        reciver_name: item.reciver_name,
        reciver_address: item.reciver_address,
        reciver_pincode: item.reciver_pincode,
        reciver_post: item.reciver_pincode,
        reciver_distrcit: item.reciver_distrcit,
        reciver_state: item.reciver_state,
        reciver_country: item.reciver_country,
        reciver_phone: item.reciver_phone,
        reciver_altphone: item.reciver_altphone,
        referance_details: prefix === "REF" ? item.id : "",
      });
    }
  };

  //

  //search pincode
  const loadPincodeData = () => {
    setLoading3(true);
    message.loading("Loading....");
    let url = API.BASR_URL + API.PINCODE_SEARCH + `${pincode}`;

    if (pincode) {
      axios
        .get(url, {
          headers: Headers(),
        })
        .then(function (response) {
          if (response.status === 200) {
            let data = response.data;
            setPincodeData(data);
            message.success("Success");
            setLoading3(false);
          } else {
            console.log("1234567");
            message.error("Oops.Something went wrongs");
          }
        })
        .catch(function (error) {
          message.error("Oops.Something went wrong...." + error);
          setLoading3(false);
        });
    }
  };
  const searchPincodeButton = (val) => {
    val.preventDefault();
    setSearchPincodeModal(true);
    loadPincodeData(pincode);
  };

  const handlePincodeChange = (event) => {
    setPincode(event.target.value);
  };

  const choosePincodeData = (item) => {
    setSearchPincodeModal(false);

    if (item) {
      formRef.current?.setFieldsValue({
        reciver_pincode: item.pincode,
        reciver_post: item.office,
        reciver_distrcit: item.district,
        reciver_state: item.state,
        reciver_country: item.country_id,
      });
    }
  };

  //Delete Box While updating
  const DeleteBox = (val, remove, field, index) => {
    // let BoxID = obje.boxs && obje.boxs[index].box_number;
    let BoxID = props.item.boxes && props.item.boxes[index].box_number;

    axios({
      method: "delete",
      url: API.BASR_URL + API.BOXES_ACTIONS + `delete/${BoxID}`,
      headers: Headers(),
    })
      .then(function (response) {
        if (response.status) {
          console.log(response);
          setexpand(index === 0 ? index : index - 1);
          remove(field.name);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // calculate TotalAmount
  let TotalAmount =
    obje.boxs &&
    obje.boxs.reduce(function (tot, arr) {
      return Number(tot) + Number(arr?.total_charge);
    }, 0);

  //get corresponding country
  const getCountryDetails = () => {
    let item = props.countries.filter((i) => i.code === obje.reciver_country);
    return item[0]?.name;
  };

  //clear collection-redux
  const clearForm = () => {
    dispatch(clear());
    props.onCancel();
  };

  return (
    <div>
      <Modal
        title={
          <div className="newFormModalTitle">
            {props.mode === "post" ? "New" : "Update"} {"   "}Collection Form
          </div>
        }
        visible={props.visible}
        onCancel={props.mode === "post" ? props.onCancel : () => clearForm()}
        width={width / 1.15}
        maskClosable={false}
        closeIcon={
          <div>
            <IoIosClose color="#fff" size={30} />
          </div>
        }
        footer={false}
      >
        <div>
          <Form
            ref={formRef}
            hideRequiredMark
            onFinish={onFinish}
            onValuesChange={handleTotal}
            onFinishFailed={onFinishFailed}
            scrollToFirstError
            initialValues={
              props.mode === "put"
                ? {
                    total_value_inr_edit: true,
                    boxs: props.item && props.item.boxes,
                    service_type:
                      props.servicetype && props.servicetype.length
                        ? props.servicetype[0].id
                        : null,
                    number_boxes: props.item && props.item.number_boxes,
                    total_weight: props.item && props.item.total_weight,
                    collected_by: props.item && props.item.collected_by,
                    collected_datetime:
                      props.item && moment(props.item.collected_datetime),

                    cargo_type:
                      props.data && props.data.cargo_type
                        ? props.data.cargo_type
                        : "air",
                    sender_name: props.item && props.item.sender_name,
                    sender_address: props.item && props.item.sender_address,
                    sender_phone: props.item && props.item.sender_phone,
                    sender_phone_alt: props.item && props.item.sender_phone_alt,
                    reciver_name: props.item && props.item.reciver_name,
                    reciver_address: props.item && props.item.reciver_address,
                    reciver_pincode: props.item && props.item.reciver_pincode,
                    reciver_post: props.item && props.item.reciver_post,
                    reciver_distrcit: props.item && props.item.reciver_distrcit,
                    reciver_state: props.item && props.item.reciver_state,
                    reciver_country: props.item && props.item.reciver_country,
                    reciver_phone: props.item && props.item.reciver_phone,
                    reciver_altphone: props.item && props.item.reciver_altphone,
                    total_amount: props.item && props.item.total_amount,

                    pickup_request_id:
                      props.data && props.data.pickup_request_id,
                    agent_id: props.item && props.item.agent_id,
                    agent_name: props.item && props.item.agent_name,
                    referance_details:
                      props.item && props.item.referance_details,
                    instructions: props.item && props.item.instructions,
                    active: true,
                  }
                : {
                    boxs: obje && obje.boxs,
                    service_type:
                      props.servicetype && props.servicetype.length
                        ? props.servicetype[0].id
                        : null,
                    number_boxes: obje && obje.number_boxes,
                    total_weight: obje && obje.total_weight,
                    collected_by: obje && obje.collected_by,
                    collected_datetime: obje && moment(obje.collected_datetime),

                    cargo_type:
                      obje && obje.cargo_type ? obje.cargo_type : "air",
                    sender_name: obje && obje.sender_name,
                    sender_address: obje && obje.sender_address,
                    sender_phone: obje && obje.sender_phone,
                    sender_phone_alt: obje && obje.sender_phone_alt,
                    reciver_name: obje && obje.reciver_name,
                    reciver_address: obje && obje.reciver_address,
                    reciver_pincode: obje && obje.reciver_pincode,
                    reciver_post: obje && obje.reciver_post,
                    reciver_distrcit: obje && obje.reciver_distrcit,
                    reciver_state: obje && obje.reciver_state,
                    reciver_country: obje && obje.reciver_country,
                    reciver_phone: obje && obje.reciver_phone,
                    reciver_altphone: obje && obje.reciver_altphone,
                    agent_id: obje && obje.agent_id,
                    referance_details: obje && obje.referance_details,
                    instructions: obje && obje.instructions,
                    created_from: "web",
                  }
            }
          >
            <div className="collection-customerform-box2">
              <Tooltip title={"Reset Form"}>
                <Button
                  onClick={() => clearForm()}
                  icon={<BiReset color="#fff" />}
                  type="primary"
                  size="small"
                >
                  Reset
                </Button>
              </Tooltip>
            </div>
            <br />
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div className="new-collection-form-txt1">
                  Boxes Details
                  {obje.boxs && obje.boxs.length ? (
                    <TiTickOutline style={{ color: "green" }} />
                  ) : (
                    ""
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Form.List
                  name="boxs"
                  initialValue={[
                    {
                      box_number: `${Auth.id}${moment().unix()}`,
                    },
                  ]}
                >
                  {(fields, { add, remove }) => {
                    return (
                      <Table bordered size="sm" className="formtable">
                        {fields.map((field, index) => (
                          <>
                            <thead
                              key={index}
                              className="FormBoxHeader"
                              style={{ borderTop: 10 }}
                            >
                              <tr>
                                <th
                                  colSpan={2}
                                  className="FormItemBoxHeadertxt"
                                >
                                  <Form.Item
                                    label={
                                      <span style={{ color: "#fff" }}>
                                        Box {index + 1}
                                      </span>
                                    }
                                    {...field}
                                    name={[field.name, "box_number"]}
                                    fieldKey={[field.fieldKey, "box_number"]}
                                    style={styles.FormItem}
                                  >
                                    <Input
                                      size="small"
                                      placeholder="Box Number"
                                      readOnly
                                      style={styles.FormInput2}
                                    />
                                  </Form.Item>
                                </th>
                                <th colSpan={2} className="">
                                  {expand === index ? null : (
                                    <Form.Item
                                      label={
                                        <span style={{ color: "#fff" }}>
                                          Weight
                                        </span>
                                      }
                                      {...field}
                                      name={[field.name, "chargable_weight"]}
                                      fieldKey={[
                                        field.fieldKey,
                                        "chargable_weight",
                                      ]}
                                      style={styles.FormItem}
                                    >
                                      <Input
                                        size="small"
                                        placeholder=""
                                        readOnly
                                        style={styles.FormInput2}
                                      />
                                    </Form.Item>
                                  )}
                                </th>
                                <th colSpan={2} className="">
                                  {expand === index ? (
                                    <Form.Item
                                      label={
                                        <span style={{ color: "#fff" }}>
                                          Total Value
                                        </span>
                                      }
                                      {...field}
                                      name={[field.name, "total_value"]}
                                      fieldKey={[field.fieldKey, "total_value"]}
                                      style={styles.FormItem}
                                    >
                                      <InputNumber
                                        placeholder="0"
                                        readOnly
                                        size="small"
                                        style={styles.FormInput2}
                                      />
                                    </Form.Item>
                                  ) : (
                                    <Form.Item
                                      label={
                                        <span style={{ color: "#fff" }}>
                                          Service
                                        </span>
                                      }
                                      {...field}
                                      name={[field.name, "service_type"]}
                                      fieldKey={[
                                        field.fieldKey,
                                        "service_type",
                                      ]}
                                      style={styles.FormItem}
                                    >
                                      <Input
                                        size="small"
                                        style={styles.FormInput2}
                                        placeholder=""
                                        readOnly
                                      />
                                    </Form.Item>
                                  )}
                                </th>
                                <th colSpan={2} className="">
                                  <Form.Item
                                    label={
                                      <span style={{ color: "#fff" }}>
                                        Amount
                                      </span>
                                    }
                                    {...field}
                                    name={[field.name, "total_charge"]}
                                    fieldKey={[field.fieldKey, "total_charge"]}
                                    style={styles.FormItem}
                                  >
                                    <Input
                                      size="small"
                                      style={styles.FormInput2}
                                      placeholder=""
                                      readOnly
                                    />
                                  </Form.Item>
                                </th>
                                <th>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "box_ref_no"]}
                                    fieldKey={[field.fieldKey, "box_ref_no"]}
                                    style={styles.FormItem3}
                                  >
                                    <Input
                                      placeholder="reference number"
                                      style={styles.FormInput3}
                                    />
                                  </Form.Item>
                                </th>
                                <th colSpan={1}>
                                  {fields.length === 1 ? null : (
                                    <div className="tableAction">
                                      {expand === index ? null : (
                                        <MdOutlineKeyboardArrowDown
                                          size={20}
                                          color="#fff"
                                          style={{
                                            marginBottom: 2,
                                            marginRight: 10,
                                          }}
                                          onClick={() => setexpand(index)}
                                        />
                                      )}
                                      {props.mode === "put" ? (
                                        <Popconfirm
                                          title="Delete the Box"
                                          description="Are you sure to delete this Box?"
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={(val) =>
                                            DeleteBox(val, remove, field, index)
                                          }
                                        >
                                          <IoMdRemoveCircleOutline
                                            size={20}
                                            color="#fff"
                                            style={{ marginBottom: 2 }}
                                            // onClick={() => {
                                            //   setexpand(
                                            //     index === 0 ? index : index - 1
                                            //   );
                                            //   remove(field.name);
                                            // }}
                                          />
                                        </Popconfirm>
                                      ) : (
                                        <IoMdRemoveCircleOutline
                                          size={20}
                                          color="#fff"
                                          style={{ marginBottom: 2 }}
                                          onClick={() => {
                                            setexpand(
                                              index === 0 ? index : index - 1
                                            );
                                            remove(field.name);
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}
                                </th>
                              </tr>
                            </thead>
                            {expand === index ? (
                              <tbody style={{ borderTop: 10 }}>
                                <tr>
                                  <td colSpan={10}>
                                    <BoxItemsForm
                                      items={props.item}
                                      fieldKey={field.name}
                                      mode={props.mode}
                                      obje={obje}
                                    />
                                  </td>
                                </tr>
                                <tr className="FormBoxFooter">
                                  <td colSpan={3}>
                                    <Form.Item
                                      label="Service Type"
                                      {...field}
                                      name={[field.name, "service_type"]}
                                      fieldKey={[
                                        field.fieldKey,
                                        "service_type",
                                      ]}
                                      style={styles.FormItemNew}
                                    >
                                      <Select
                                        placeholder="Service Type"
                                        size="small"
                                        style={{ width: "100%" }}
                                        showSearch
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {props.servicetype.map((option) => (
                                          <Select.Option
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option.name}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td colSpan={3}>
                                    <Form.Item
                                      label="size"
                                      {...field}
                                      name={[field.name, "size"]}
                                      fieldKey={[field.fieldKey, "size"]}
                                      style={styles.FormItemNew}
                                    >
                                      <Select
                                        placeholder="Box Type"
                                        size="small"
                                        style={{ width: "100%" }}
                                        showSearch
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {props.boxtype.map((option) => (
                                          <Select.Option
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option.name}
                                          </Select.Option>
                                        ))}
                                        <Select.Option key={0} value={0}>
                                          Other
                                        </Select.Option>
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td colSpan={1}>
                                    <Form.Item
                                      label="L"
                                      {...field}
                                      name={[field.name, "length"]}
                                      fieldKey={[field.fieldKey, "length"]}
                                      style={styles.FormItem2New}
                                    >
                                      <Input
                                        placeholder="0"
                                        size="small"
                                        style={styles.FormInput}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td colSpan={1}></td>
                                  <td colSpan={1}>
                                    <Form.Item
                                      label="B"
                                      {...field}
                                      name={[field.name, "breadth"]}
                                      fieldKey={[field.fieldKey, "breadth"]}
                                      style={styles.FormItem2New}
                                    >
                                      <Input
                                        placeholder="0"
                                        size="small"
                                        style={styles.FormInput}
                                      />
                                    </Form.Item>
                                  </td>

                                  <td colSpan={1}>
                                    <Form.Item
                                      label="H"
                                      {...field}
                                      name={[field.name, "height"]}
                                      fieldKey={[field.fieldKey, "height"]}
                                      style={styles.FormItem2New}
                                    >
                                      <Input
                                        placeholder="0"
                                        size="small"
                                        style={styles.FormInput}
                                      />
                                    </Form.Item>
                                  </td>
                                </tr>
                                <tr className="FormBoxFooter">
                                  <td colSpan={2}>
                                    <Form.Item
                                      label="Actual Weight"
                                      {...field}
                                      name={[field.name, "weight"]}
                                      fieldKey={[field.fieldKey, "weight"]}
                                      style={styles.FormItemNew}
                                    >
                                      <InputNumber
                                        size="small"
                                        style={styles.FormInput}
                                        placeholder="0"
                                      />
                                    </Form.Item>
                                  </td>

                                  <td colSpan={2}>
                                    <Form.Item
                                      label={"Volume Weight"}
                                      {...field}
                                      name={[field.name, "volume_weight"]}
                                      fieldKey={[
                                        field.fieldKey,
                                        "volume_weight",
                                      ]}
                                      style={styles.FormItem}
                                    >
                                      <InputNumber
                                        placeholder="0"
                                        readOnly
                                        style={styles.FormInput}
                                        size="small"
                                      />
                                    </Form.Item>
                                  </td>
                                  <td colSpan={4}>{``}</td>
                                  <td colSpan={2}>
                                    <div className="tableAction">
                                      <div style={{ marginRight: 10 }}>
                                        <Form.Item
                                          {...field}
                                          name={[
                                            field.name,
                                            "chargable_weight_edit",
                                          ]}
                                          fieldKey={[
                                            field.fieldKey,
                                            "chargable_weight_edit",
                                          ]}
                                          style={styles.FormItem}
                                          valuePropName={
                                            [
                                              field.fieldKey,
                                              "chargable_weight_edit",
                                            ]
                                              ? "checked"
                                              : "null"
                                          }
                                        >
                                          <Switch size="small" />
                                        </Form.Item>
                                      </div>
                                      <div>
                                        <Form.Item
                                          label={"Chargable Weight"}
                                          {...field}
                                          name={[
                                            field.name,
                                            "chargable_weight",
                                          ]}
                                          fieldKey={[
                                            field.fieldKey,
                                            "chargable_weight",
                                          ]}
                                          style={styles.FormItem}
                                        >
                                          <InputNumber
                                            placeholder="0"
                                            style={styles.FormInput}
                                            size="small"
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="FormBoxFooter">
                                  <td colSpan={2}>
                                    <Form.Item
                                      label={"Rate / KG"}
                                      {...field}
                                      name={[field.name, "rate"]}
                                      fieldKey={[field.fieldKey, "rate"]}
                                      style={styles.FormItemNew}
                                    >
                                      <InputNumber
                                        placeholder="Rate"
                                        size="small"
                                        style={styles.FormInput}
                                        onChange={() => setEditRate(true)}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td colSpan={2}>
                                    <Form.Item
                                      label={"Packing Charge"}
                                      {...field}
                                      name={[field.name, "packing_charge"]}
                                      fieldKey={[
                                        field.fieldKey,
                                        "packing_charge",
                                      ]}
                                      style={styles.FormItem}
                                    >
                                      <InputNumber
                                        placeholder="Charge"
                                        size="small"
                                        style={styles.FormInput}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td colSpan={2}>
                                    <Form.Item
                                      label={"Duty"}
                                      {...field}
                                      name={[field.name, "duty"]}
                                      fieldKey={[field.fieldKey, "duty"]}
                                      style={styles.FormItem}
                                    >
                                      <InputNumber
                                        placeholder="Charge"
                                        size="small"
                                        style={styles.FormInput}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td colSpan={2}>
                                    <Form.Item
                                      label={"Other"}
                                      {...field}
                                      name={[field.name, "other_charges"]}
                                      fieldKey={[
                                        field.fieldKey,
                                        "other_charges",
                                      ]}
                                      style={styles.FormItem}
                                    >
                                      <InputNumber
                                        placeholder="Charge"
                                        size="small"
                                        style={styles.FormInput}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td colSpan={4}>{``}</td>
                                </tr>
                                <tr className="FormBoxFooter">
                                  <td colSpan={2}>
                                    <div className="tableAction">
                                      <div style={{ marginRight: 10 }}>
                                        <Form.Item
                                          {...field}
                                          name={[
                                            field.name,
                                            "total_value_edit",
                                          ]}
                                          fieldKey={[
                                            field.fieldKey,
                                            "total_value_edit",
                                          ]}
                                          style={styles.FormItemNew}
                                          valuePropName={
                                            [field.fieldKey, "total_value_edit"]
                                              ? "checked"
                                              : "null"
                                          }
                                        >
                                          <Switch size="small" />
                                        </Form.Item>
                                      </div>
                                      <div>
                                        <Form.Item
                                          label={"Total Value"}
                                          {...field}
                                          name={[field.name, "total_value"]}
                                          fieldKey={[
                                            field.fieldKey,
                                            "total_value",
                                          ]}
                                          style={styles.FormItemNew}
                                        >
                                          <InputNumber
                                            placeholder="Charge"
                                            size="small"
                                            style={styles.FormInput}
                                          />
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </td>
                                  <td colSpan={2}>
                                    <Form.Item
                                      label={"Total Value (USD)"}
                                      {...field}
                                      name={[field.name, "total_value_usd"]}
                                      fieldKey={[
                                        field.fieldKey,
                                        "total_value_usd",
                                      ]}
                                      style={styles.FormItemNew}
                                    >
                                      <InputNumber
                                        placeholder="Charge"
                                        size="small"
                                        style={styles.FormInput}
                                        readOnly
                                      />
                                    </Form.Item>
                                  </td>
                                  <td colSpan={2}>
                                    <div style={{ display: "flex" }}>
                                      <div style={{ marginRight: 10 }}>
                                        <Form.Item
                                          {...field}
                                          name={[
                                            field.name,
                                            "total_value_inr_edit",
                                          ]}
                                          fieldKey={[
                                            field.fieldKey,
                                            "total_value_inr_edit",
                                          ]}
                                          style={styles.FormItemNew}
                                          valuePropName={
                                            [
                                              field.fieldKey,
                                              "total_value_inr_edit",
                                            ]
                                              ? "checked"
                                              : "null"
                                          }
                                        >
                                          <Switch size="small" />
                                        </Form.Item>
                                      </div>
                                      <Form.Item
                                        label={"Total Value (INR)"}
                                        {...field}
                                        name={[field.name, "total_value_inr"]}
                                        fieldKey={[
                                          field.fieldKey,
                                          "total_value_inr",
                                        ]}
                                        style={styles.FormItem}
                                      >
                                        <InputNumber
                                          placeholder="Charge"
                                          size="small"
                                          style={styles.FormInput}
                                        />
                                      </Form.Item>
                                    </div>
                                  </td>
                                  <td colSpan={4}></td>
                                </tr>
                              </tbody>
                            ) : null}
                          </>
                        ))}
                        <br />
                        <Button
                          onClick={() => {
                            setexpand(fields.length);
                            add();
                          }}
                          style={{
                            borderRadius: "6px",
                          }}
                          type="primary"
                          icon={<IoIosAddCircle color="#fff" size={25} />}
                        >
                          <span className="newBoxSpan">Add New Box</span>
                        </Button>
                      </Table>
                    );
                  }}
                </Form.List>
                <br />
                <Row>
                  <Col sm="3" xs="12">
                    <div className="formlabel label-red">Number of Boxes</div>
                    <Form.Item name={"number_boxes"}>
                      <Input
                        style={styles.FormItemInput}
                        placeholder="Volume Weight"
                        readOnly
                      />
                    </Form.Item>
                  </Col>
                  <Col sm="3" xs="12">
                    <div className="formlabel label-red">
                      Total Chargable Weight
                    </div>
                    <Form.Item name={"total_weight"}>
                      <Input
                        style={styles.FormItemInput}
                        placeholder="Chargable Weight"
                        readOnly
                      />
                    </Form.Item>
                  </Col>

                  <Col sm="3" xs="12">
                    <div className="formlabel label-red">Total Amount</div>
                    <Form.Item name={"total_amount"}>
                      <Input
                        style={styles.FormItemInput}
                        placeholder="Total Amount"
                        readOnly
                      />
                    </Form.Item>
                  </Col>

                  <Col sm="3" xs="12">
                    <div className="formlabel label-red">
                      Collected Date Time
                    </div>
                    <Form.Item
                      name="collected_datetime"
                      rules={[{ required: true, message: "required" }]}
                    >
                      <DatePicker
                        style={styles.FormItemInput}
                        placeholder="select date time"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    sm="4"
                    xs="12"
                    style={{ visibility: "hidden", display: "none" }}
                  >
                    <div className="formlabel label-red">Collected By</div>
                    <Form.Item name="collected_by">
                      <Input />
                    </Form.Item>
                    <div className="d-none">
                      <Form.Item label="status" name="status">
                        <Input />
                      </Form.Item>
                      <Form.Item label="created_from" name="created_from">
                        <Input />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <div className="new-collection-form-txt1">
                  Customer Info
                  {obje &&
                  obje.sender_name &&
                  obje.sender_address &&
                  obje.sender_phone &&
                  obje.sender_phone_alt &&
                  obje.reciver_name &&
                  obje.reciver_address &&
                  obje.reciver_country &&
                  obje.reciver_pincode &&
                  obje.reciver_post &&
                  obje.reciver_distrcit &&
                  obje.reciver_state &&
                  obje.reciver_phone &&
                  obje.reciver_altphone ? (
                    <TiTickOutline style={{ color: "green" }} />
                  ) : (
                    ""
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div align="center">
                  <Input.Group compact style={styles.InputGroups}>
                    <Input.Search
                      addonBefore={prefixSelector}
                      style={{
                        width: "calc(100% - 600px)",
                      }}
                      placeholder="Search By Phone Number Or Box Number"
                      onChange={(val) => handleDataChange(val)}
                      value={search}
                      onSearch={(e, val) => onSearchPickup(val)}
                      enterButton
                      allowClear
                    />
                  </Input.Group>
                </div>
                <div className="collection-customerform-box">
                  <div className="new-collection-form-txt1">Sender Info</div>
                </div>

                <Row>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="sender_name"
                      label={
                        <div className="collection-customerform-formlabel">
                          Sender Name
                        </div>
                      }
                      rules={[
                        { required: false, message: "required" },
                        {
                          max: 100,
                          message: "Maximum length exceeded",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Sender Name"
                        style={styles.FormItemInput}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="sender_address"
                      label={
                        <div className="collection-customerform-formlabel">
                          Address
                        </div>
                      }
                      rules={[
                        { required: false, message: "required" },
                        {
                          max: 250,
                          message: "Maximum length exceeded",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Enter Address"
                        style={styles.FormItemInputAdress}
                        rows={4}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm="6" xs="12" style={{ marginTop: "-70px" }}>
                    <Row>
                      <Col sm="6" xs="12">
                        <Form.Item
                          name="sender_phone"
                          label={
                            <div className="collection-customerform-formlabel">
                              Phone Number
                            </div>
                          }
                          rules={[{ required: false, message: "required" }]}
                          style={{ order: 2 }}
                        >
                          <Input
                            placeholder="Enter phone number"
                            style={styles.FormItemInput}
                            tabIndex={0}
                            controls={false}
                            maxLength={20}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm="6" xs="12">
                        <Form.Item
                          name="sender_phone_alt"
                          label={
                            <div className="collection-customerform-formlabel">
                              Alt. Phone Number
                            </div>
                          }
                          rules={[{ required: false, message: "required" }]}
                        >
                          <Input
                            placeholder="Enter phone number"
                            style={styles.FormItemInput}
                            controls={false}
                            maxLength={11}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div
                  className="collection-customerform-box"
                  style={{ marginBottom: 5 }}
                >
                  <div className="new-collection-form-txt1">Reciever Info</div>
                  <div>
                    <Input.Group compact style={styles.InputGroups}>
                      <Input
                        placeholder="Search Pincode"
                        style={{
                          width: "calc(100% - 50px)",
                        }}
                        onChange={handlePincodeChange}
                        onPressEnter={(val) => searchPincodeButton(val)}
                        allowClear
                      />
                      <Button
                        type="primary"
                        onClick={(val) => searchPincodeButton(val)}
                        icon={<AiOutlineSearch />}
                      />
                    </Input.Group>
                  </div>
                </div>
                <Row>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="reciver_name"
                      label={
                        <div className="collection-customerform-formlabel">
                          Reciever Name
                        </div>
                      }
                      rules={[{ required: false, message: "required" }]}
                    >
                      <Input
                        placeholder="Enter Name "
                        style={styles.FormItemInput}
                        maxLength={100}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm="6" xs="12">
                    <Form.Item
                      name="reciver_address"
                      label={
                        <div className="collection-customerform-formlabel">
                          Address
                        </div>
                      }
                      rules={[{ required: false, message: "required" }]}
                    >
                      <Input.TextArea
                        placeholder="Enter Address"
                        style={styles.FormItemInputAdress}
                        rows={4}
                        maxLength={255}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm="6" xs="12" style={{ marginTop: "-70px" }}>
                    <Row>
                      <Col sm="6" xs="12">
                        <Form.Item
                          name="reciver_country"
                          label={
                            <div className="collection-customerform-formlabel">
                              Country
                            </div>
                          }
                          rules={[{ required: true, message: "required" }]}
                        >
                          <Select
                            bordered={false}
                            className="CustomerForm-Select"
                            placeholder="Select Country"
                            showSearch
                            allowClear
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ margin: 4, textTransform: "uppercase" }}
                          >
                            {props.countries.map((option) => (
                              <Select.Option
                                key={option.code}
                                value={option.id}
                              >
                                {option.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col sm="6" xs="12">
                        <Form.Item
                          name="reciver_pincode"
                          label={
                            <div className="collection-customerform-formlabel">
                              Pincode
                            </div>
                          }
                        >
                          <InputNumber
                            placeholder="Enter Pincode"
                            style={styles.FormItemInput}
                            maxLength={10}
                            controls={false}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={styles.RecieverFormRow}>
                  <Col sm="2" xs="12" style={styles.RecieverFormRowItem}>
                    <Form.Item
                      name="reciver_post"
                      label={
                        <div className="collection-customerform-formlabel">
                          Post
                        </div>
                      }
                      rules={[{ required: false, message: "required" }]}
                    >
                      <Input
                        placeholder="Enter Post"
                        style={styles.FormItemInput}
                        maxLength={100}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm="2" xs="12" style={styles.RecieverFormRowItem}>
                    <Form.Item
                      name="reciver_distrcit"
                      label={
                        <div className="collection-customerform-formlabel">
                          District
                        </div>
                      }
                      rules={[{ required: false, message: "required" }]}
                    >
                      <Input
                        placeholder="Enter District"
                        style={styles.FormItemInput}
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>

                  <Col sm="2" xs="12" style={styles.RecieverFormRowItem}>
                    <Form.Item
                      name="reciver_state"
                      label={
                        <div className="collection-customerform-formlabel">
                          State
                        </div>
                      }
                      rules={[{ required: false, message: "required" }]}
                    >
                      <Input
                        placeholder="Enter State"
                        style={styles.FormItemInput}
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={2} xs="12" style={styles.RecieverFormRowItem}>
                    <Form.Item
                      name="reciver_phone"
                      label={
                        <div className="collection-customerform-formlabel">
                          Phone
                        </div>
                      }
                      rules={[{ required: false, message: "required" }]}
                    >
                      <Input
                        placeholder="Enter Contact Number"
                        style={styles.FormItemInput}
                        maxLength={20}
                        controls={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm="2" xs="12" style={styles.RecieverFormRowItem}>
                    <Form.Item
                      name="reciver_altphone"
                      label={
                        <div className="collection-customerform-formlabel">
                          Alt. phone
                        </div>
                      }
                      rules={[{ required: false, message: "required" }]}
                    >
                      <Input
                        placeholder="Enter Contact Number"
                        style={styles.FormItemInput}
                        maxLength={20}
                        controls={false}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel2bh-header"
              >
                <div className="new-collection-form-txt1">
                  Other Details
                  {obje &&
                  obje.agent_id &&
                  obje.referance_details &&
                  obje.instructions ? (
                    <TiTickOutline style={{ color: "green" }} />
                  ) : (
                    ""
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Row>
                  <Col sm="1" xs="12"></Col>
                  <Col sm="5" xs="12">
                    <Form.Item
                      name="agent_id"
                      label="Agent"
                      rules={[{ required: true, message: "required" }]}
                    >
                      <Select
                        bordered={false}
                        className="CustomerForm-Select"
                        placeholder="Select Agent"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {props.agents.map((option) => (
                          <Option key={option.id} value={option.id}>
                            {option.user && option.user.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col sm="5" xs="12">
                    <Form.Item
                      name="referance_details"
                      label={
                        <div className="collection-customerform-formlabel">
                          Reference Details
                        </div>
                      }
                      rules={[{ required: false, message: "required" }]}
                    >
                      <Input style={styles.FormItemOtherDetails} />
                    </Form.Item>
                  </Col>
                  <Col sm="1" xs="12"></Col>
                </Row>
                <Row>
                  <Col sm="1" xs="12"></Col>
                  <Col sm="5" xs="12">
                    <Form.Item
                      name="instructions"
                      label={
                        <div className="collection-customerform-formlabel">
                          Instructions
                        </div>
                      }
                      rules={[{ required: false, message: "required" }]}
                    >
                      <Input.TextArea style={styles.FormItemOtherDetails} />
                    </Form.Item>
                  </Col>
                  <Form.Item
                    name="created_from"
                    style={{ visibility: "hidden", display: "none" }}
                  >
                    <Input defaultValue="created_from" value="web" />
                  </Form.Item>
                  <Col sm="1" xs="12"></Col>
                </Row>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel2bh-header"
              >
                <div className="new-collection-form-txt1">OverView</div>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Table className="formTable" borderless>
                    <thead>
                      <tr>
                        <td
                          colSpan={4}
                          style={{ backgroundColor: "#1e88e5", color: "#fff" }}
                          className="collection-fulldetails-txt1"
                        >
                          Full Details
                        </td>
                      </tr>
                    </thead>
                    <tbody className="collection-fulldetails-txt2">
                      <tr>
                        <td colSpan={2}>TOTAL WEIGHT</td>
                        <th colSpan={2}>
                          {props.item && props.item.total_weight
                            ? props.item.total_weight
                            : obje && obje.total_weight}
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={2}>TOTAL AMOUNT</td>
                        <th colSpan={2}>{obje && TotalAmount}</th>
                      </tr>
                      <tr>
                        <td colSpan={2}>instructions</td>
                        <th colSpan={2}>
                          {props.item && props.item.instructions
                            ? props.item.instructions
                            : obje && obje.instructions}
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={2}>COLLECTED BY</td>
                        <th colSpan={2}>
                          {props.mode === "post"
                            ? `${Auth.name}`
                            : `${
                                props.item.boxes &&
                                props.item.boxes[0] &&
                                props.item.boxes[0]?.created_users?.name
                              }`}
                          {/* {Auth.name} */}
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={2}>COLLECTED DATE TIME :</td>
                        <th colSpan={2}>
                          {props.item && props.item.collected_datetime
                            ? moment(props.item.collected_datetime).format(
                                "DD/MM/YYYY   hh-mm A"
                              )
                            : obje &&
                              moment(obje.collected_datetime).format(
                                "DD/MM/YYYY   hh-mm A"
                              )}
                        </th>
                      </tr>
                      <tr style={{ backgroundColor: "#e3e3e3", color: "#000" }}>
                        <th colSpan={2}>SENDER DETAILS</th>
                        <th colSpan={2}>RECIEVER DETAILS</th>
                      </tr>

                      <tr>
                        <td colSpan={1}> Sender Name</td>
                        <th colSpan={1}>
                          {props.item && props.item.sender_name
                            ? props.item.sender_name
                            : obje && obje.sender_name}
                        </th>
                        <td colSpan={1}> Reciever Name </td>
                        <th colSpan={1}>
                          {props.item && props.item.reciver_name
                            ? props.item.reciver_name
                            : obje && obje.reciver_name}
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={1}> Sender Phone</td>
                        <th colSpan={1}>
                          {props.item && props.item.sender_phone
                            ? props.item.sender_phone
                            : obje && obje.sender_phone}
                        </th>
                        <td colSpan={1}> Reciever Phone </td>
                        <th colSpan={1}>
                          {props.item && props.item.reciver_phone
                            ? props.item.reciver_phone
                            : obje && obje.reciver_phone}
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={1}>Sender Alt. Phone</td>
                        <td colSpan={1}></td>
                        <td colSpan={1}> Reciever Alt. Phone </td>
                        <th colSpan={1}>
                          {props.item && props.item.reciver_altphone
                            ? props.item.reciver_altphone
                            : obje && obje.reciver_altphone}
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={1}> Sender Address</td>
                        <th colSpan={1}>
                          {props.item && props.item.sender_address
                            ? props.item.sender_address
                            : obje && obje.sender_address}
                        </th>
                        <td colSpan={1}> Reciever Address </td>
                        <th colSpan={1}>
                          {props.item && props.item.reciver_address
                            ? props.item.reciver_address
                            : obje && obje.reciver_address}
                        </th>
                      </tr>

                      <tr>
                        <td colSpan={1}> </td>
                        <td colSpan={1}></td>
                        <td colSpan={1}> Reciever Pincode </td>
                        <th colSpan={1}>
                          {props.item && props.item.reciver_pincode
                            ? props.item.reciver_pincode
                            : obje && obje.reciver_pincode}
                        </th>
                      </tr>

                      <tr>
                        <td colSpan={1}> </td>
                        <td colSpan={1}></td>
                        <td colSpan={1}> Reciever Country </td>
                        <th colSpan={1}>
                          {/* {obje && obje.reciver_country}, */}
                          {getCountryDetails()}
                        </th>
                      </tr>
                      <tr>
                        <th colSpan={4} style={{ backgroundColor: "#e3e3e3" }}>
                          Box Details
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={4}>
                          <Table className="formTable">
                            {obje.boxs &&
                              obje.boxs.length &&
                              obje.boxs.map((box, index) => {
                                return (
                                  <>
                                    <tr style={{ background: "#2db7f5" }}>
                                      <th colSpan={7}>
                                        Box {index + 1} : {box.box_number}
                                      </th>
                                    </tr>
                                    <tr>
                                      <td colSpan={1}>
                                        service type : <b>{box.service_type}</b>{" "}
                                      </td>
                                      <td colSpan={1}>
                                        size:<b>{box.size}</b>
                                      </td>
                                      <td colSpan={1}>
                                        L :<b>{box.length}</b>
                                      </td>
                                      <td colSpan={1}>
                                        B :<b>{box.breadth}</b>
                                      </td>
                                      <td colSpan={1}>
                                        H:<b>{box.height}</b>
                                      </td>
                                      <td colSpan={1}>
                                        W:<b>{box.weight}</b>
                                      </td>
                                      <td colSpan={1}>H:</td>
                                    </tr>

                                    <tr>
                                      <td colSpan={1}>
                                        VOLUME KG : <b>{box.volume_weight}</b>
                                      </td>
                                      <td colSpan={1}>
                                        ACTUAL KG :<b>{box.weight}</b>
                                      </td>
                                      <td colSpan={1}>
                                        RATE / KG:<b>{box.rate}</b>
                                      </td>
                                      <td colSpan={1}>
                                        packing Charge :{" "}
                                        <b>{box.packing_charge}</b>
                                      </td>
                                      <td colSpan={1}>
                                        duty :<b>{box.duty}</b>
                                      </td>
                                      <td colSpan={1}>
                                        other_charges:<b>{box.other_charges}</b>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td colSpan={1}>discount:</td>
                                      <td colSpan={1}>
                                        t amount:<b>{box.total_charge}</b>
                                      </td>
                                      <td colSpan={1}>
                                        T value:<b>{box.total_value}</b>
                                      </td>
                                      <td colSpan={1}>
                                        t value $:<b>{box.total_value_usd}</b>
                                      </td>
                                      <td colSpan={1}>
                                        t value inr :
                                        <b>{box.total_value_inr}</b>
                                      </td>

                                      <td colSpan={1}></td>
                                    </tr>
                                    <tr>
                                      <td colSpan={7}>
                                        <table className="formTable">
                                          <tr
                                            style={{
                                              backgroundColor: "#e2e2e2",
                                            }}
                                          >
                                            <td colSpan={1}>Sl No</td>
                                            <td colSpan={1}>item</td>
                                            <td colSpan={1}>quantity</td>
                                            <td colSpan={1}>value</td>
                                            <td colSpan={1}>brand</td>
                                          </tr>
                                          {box.boxitems &&
                                            box.boxitems.length &&
                                            box.boxitems.map((item, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td>{index + 1}</td>
                                                  <td>{item?.item}</td>
                                                  <td>{item?.quantity}</td>
                                                  <td>{item?.value}</td>
                                                  <td>{item?.brand}</td>
                                                </tr>
                                              );
                                            })}
                                        </table>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                          </Table>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </AccordionDetails>
            </Accordion>
            <br />
            <Row>
              <Col sm="6" xs="6"></Col>
              <Col sm="3" xs="3">
                <Button
                  onClick={() => props.onCancel()}
                  block
                  size="large"
                  style={styles.BackButton}
                >
                  Cancel
                </Button>
              </Col>
              <Col sm="3" xs="3">
                <Button
                  loading={loading}
                  style={styles.NextButton}
                  block
                  size="large"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        {searchModal ? (
          loading2 ? (
            <LoadingBox />
          ) : (
            <SearchModal
              visible={searchModal}
              onClose={() => setSearchModal(false)}
              data={searchData}
              select={(val) => {
                chooseSenderAndReciverData(val);
              }}
            />
          )
        ) : null}
        {searchPincodeModal ? (
          loading3 ? (
            <LoadingBox />
          ) : (
            <PincodeSearchModel
              visible={searchPincodeModal}
              onClose={() => setSearchPincodeModal(false)}
              data={pincodeData}
              select={(val) => {
                choosePincodeData(val);
              }}
            />
          )
        ) : null}
      </Modal>
    </div>
  );
}

export default NewFormModal;
